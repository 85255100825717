import React from "react";
import Skeleton,{SkeletonTheme} from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export default function SkeletonComponent() {
  return (
    <>
    <div>

     <SkeletonTheme >
     <Skeleton
            height={50}
            width={"7%"}
            count={1}
            inline={true}
            duration={2.5}
          />
          <Skeleton
            height={50}
            width={"5%"}
            count={1}
            inline={true}
            duration={2.5}
          />
      <div style={{ width: "100%" }}>
        <Skeleton
          height={100}
          width={"25%"}
          borderRadius={"20px"}
          count={4}
          inline={true}
          duration={2.5}
        />
      </div>
      <Skeleton height={500} width={"100%"} count={1} inline={true} duration={2.5} />
     </SkeletonTheme >
     </div>

    </>
  );
}
