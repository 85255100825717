import { React, useRef, useState } from "react";
import { ApiHook } from "../../hooks/apiHook";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { contactDetailsSchema } from "../../Validator/profile";
import { useTranslation } from "react-i18next";
import SubmitButton from "../Common/buttons/SubmitButton";
import { toast } from "react-toastify";

const ContactDetailsTab = ({
  contact,
  countries,
}) => {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
  } = useForm({ resolver: yupResolver(contactDetailsSchema) });

  const [contactDetails, setContactDetails] = useState({
    address: contact.address ?? "",
    address2: contact.address2 ?? "",
    country: contact.country?.id ?? "",
    state: contact.state?.id ?? "",
    city: contact.city ?? "",
    zipCode: contact.zipCode ?? "",
    mobile: contact?.mobile ?? "",
  });
  const [isEditModeEnabled, setIsEditModeEnabled] = useState(false);

  const updateContactMutation = ApiHook.CallUpdateContactDetails(
    contactDetails
  );
  const toggleEditMode = () => {
    setIsEditModeEnabled(!isEditModeEnabled);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setContactDetails((prevCredentials) => ({
      ...prevCredentials,
      [name]: value,
    }));
  };

  const onSubmit = () => {
    updateContactMutation.mutate(contactDetails, {
      onSuccess: (res) => {
        toast.success(res?.data);
        setIsEditModeEnabled(false)
      },
    });
  };

  return (
    <div id="secondTab" className="tabcontent">
      <div className={`editSec ${isEditModeEnabled ? "disabled" : ""}`}>
        <div className="editBg">
          <span
            style={{ textDecoration: "none", cursor: "pointer" }}
            onClick={toggleEditMode}
          >
            <i
              className="fa-solid fa-pen-to-square"
              style={{ color: "#32009c" }}
            ></i>
          </span>
        </div>
      </div>
      <h3>{t("contactDetails")}</h3>
        <div className="tabcontent_form_section">
          <div className="row">
            <div className="col-md-12">
              <div className="mb-3 row tabBlockClass">
                <label
                  htmlFor="1"
                  className="col-sm-3 col-form-label labelWidthClass"
                >
                  {t("addressLine1")}
                </label>
                <div className="col-md-9 col-sm-12 col-12">
                  <input
                    {...register("address")}
                    defaultValue={contactDetails?.address}
                    type="text"
                    id="1"
                    className="form-control"
                    disabled={!isEditModeEnabled}
                    onChange={handleChange}
                  />
                  {errors.address && (
                    <span className="validation-error-message">
                      {errors.address.message}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="mb-3 row tabBlockClass">
                <label
                  htmlFor="2"
                  className="col-sm-3 col-form-label labelWidthClass"
                >
                  {t("addressLine2")}
                </label>
                <div className="col-md-9 col-sm-12 col-12">
                  <input
                    {...register("address2")}
                    defaultValue={contactDetails?.address2}
                    type="text"
                    className="form-control"
                    id="2"
                    disabled={!isEditModeEnabled}
                    onChange={handleChange}
                  />
                  {errors.address2 && errors.address2.type === "required" && (
                    <span className="validation-error-message">
                      {errors.address2.message}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="mb-3 row tabBlockClass">
                <label
                  htmlFor="3"
                  className="col-sm-3 col-form-label labelWidthClass"
                >
                  {t("country")}
                </label>
                <div className="col-md-9 col-sm-12 col-12">
                  <select
                    {...register("country")}
                    className="form-select"
                    id="3"
                    defaultValue={contactDetails?.country}
                    disabled={!isEditModeEnabled}
                    onChange={handleChange}
                  >
                    {Object.entries(countries)?.map(([key, value]) => (
                      <option key={key} value={value?.id}>
                        {value?.name}
                      </option>
                    ))}
                  </select>
                  {errors.country && errors.country.type === "required" && (
                    <span className="validation-error-message">
                      {errors.country.message}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="mb-3 row tabBlockClass">
                <label
                  htmlFor="4"
                  className="col-sm-3 col-form-label labelWidthClass"
                >
                  {t("state")}
                </label>
                <div className="col-md-9 col-sm-12 col-12">
                  <select
                    {...register("state")}
                    defaultValue={contactDetails?.state}
                    className="form-select"
                    id="4"
                    disabled={!isEditModeEnabled}
                    onChange={handleChange}
                  >
                    {/* Default option */}
                    <option key="0" value="">
                      {t("select_a_state")}
                    </option>
                    {countries?.map((country) => {
                      if (country.id === Number(contactDetails.country)) {
                        return country.States.map((state) => (
                          <option key={state.id} value={state.id}>
                            {state.name}
                          </option>
                        ));
                      }
                    })}

                    {errors.state && errors.state.type === "required" && (
                      <span className="validation-error-message">
                        {errors.state.message}
                      </span>
                    )}
                  </select>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="mb-3 row tabBlockClass">
                <label
                  htmlFor="5"
                  className="col-sm-3 col-form-label labelWidthClass"
                >
                  {t("city")}
                </label>
                <div className="col-md-9 col-sm-12 col-12">
                  <input
                    {...register("city")}
                    type="text"
                    className="form-control"
                    id="5"
                    defaultValue={contactDetails?.city}
                    disabled={!isEditModeEnabled}
                    onChange={handleChange}
                  />
                  {errors.city && errors.city.type === "required" && (
                    <span className="validation-error-message">
                      {errors.city.message}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="mb-3 row tabBlockClass">
                <label
                  htmlFor="6"
                  className="col-sm-3 col-form-label labelWidthClass"
                >
                  {t("zipCode")}
                </label>
                <div className="col-md-9 col-sm-12 col-12">
                  <input
                    {...register("zipCode")}
                    type="text"
                    className="form-control"
                    id="6"
                    defaultValue={contactDetails?.zipCode}
                    disabled={!isEditModeEnabled}
                    onChange={handleChange}
                  />
                  {errors.zipCode && (
                    <span className="validation-error-message">
                      {errors.zipCode.message}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="mb-3 row tabBlockClass">
                <label
                  htmlFor="7"
                  className="col-sm-3 col-form-label labelWidthClass"
                >
                  {t("mobileNumber")}
                </label>
                <div className="col-md-9 col-sm-12 col-12">
                  <input
                    {...register("mobile")}
                    type="text"
                    className="form-control"
                    id="7"
                    defaultValue={contactDetails?.mobile}
                    // disabled={!isEditModeEnabled}
                    disabled={true}
                    onChange={handleChange}
                  />
                  {errors.mobile && errors.mobile.type === "required" && (
                    <span className="validation-error-message">
                      {errors.mobile.message}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div
            className={`paymenytLinkBtn ${isEditModeEnabled ? "disabled" : ""}`}
          >
            <SubmitButton
              id={'1'}
              isSubmitting={!isEditModeEnabled}
              click={onSubmit}
              text="update"
              className="btn"
            />
          </div>
        </div>
    </div>
  );
};

export default ContactDetailsTab;
