import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

function UserDropdown({ props, isCustomer }) {
  const { t } = useTranslation();
  const progressBarRef = useRef(null);
  const moduleStatus = useSelector(
    (state) => state.dashboard?.appLayout?.moduleStatus
  );

  useEffect(() => {
    const strokeDashOffsetValue =
      100 - (props?.productValidity?.packageValidityPercentage ?? 100);
    progressBarRef.current.style.strokeDashoffset = strokeDashOffsetValue;
  }, []);

  return (
    <>
      <aside className="left_sidebar"></aside>
      <div className="rightSide_top_user_dropdown">
        <div className="rightSide_top_user_dropdown_avatar_sec">
          <div className="rightSide_top_user_dropdown_avatar">
            <img
              src={props?.image ? props?.image : "/images/user-profile.png"}
              alt=""
            />
            {!!moduleStatus?.kyc_status ? (
              <div className="kyc_vrfd">
                {props?.userProfile?.kycStatus ? (
                  <img src="/images/kyc_vrfd.svg" alt="" />
                ) : (
                  <img src="/images/kyc_not_vrfd.png" alt="" />
                )}
              </div>
            ) : (
              <div className="kyc_vrfd">
                <img src="/images/kyc_vrfd.svg" alt="" />
              </div>
            )}
            <svg
              className="profile_progress"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="-1 -1 34 34"
            >
              <circle
                cx="16"
                cy="16"
                r="15.9155"
                className="progress-bar__background"
              />
              <circle
                cx="16"
                cy="16"
                r="15.9155"
                className="progress-bar__progress js-progress-bar"
                ref={progressBarRef}
              />
            </svg>
          </div>
        </div>
        <div className="rightSide_top_user_dropdown_nameBOx">
          <div className="rightSide_top_user_dropdown_name">
            {props?.fullname}
          </div>
          <div className="rightSide_top_user_dropdown_id">
            {props?.username}
          </div>
          {!!moduleStatus?.product_status && (
            <div className="rightSide_top_user_dropdown_package">
              {props?.packageName}
            </div>
          )}
        </div>
      </div>
      {!isCustomer && (
        <>
        <div className="top_right_personal_dtl_box_sec">
          <div className="top_right_personal_dtl_box border-sprt">
            <span>{`${t("personalPV")}`}</span><br />
            <span>{`(cluster)`}</span>
            <strong>{props?.personalPv}</strong>
          </div>
          <div className="top_right_personal_dtl_box">
            <span>{`Personal`}</span><br />
            <span>{`Volume`}</span>
            <strong>{props?.personalBv}</strong>
          </div>
        </div>
        <div className="top_right_personal_dtl_box_sec">
          <div className="top_right_personal_dtl_box border-sprt">
            <span>{`${t("Cluster")}`}</span><br />
            <span>{`Volume`}</span>
            <strong>{props?.clusterVolume}</strong>
          </div>
          <div className="top_right_personal_dtl_box">
            <span>{`Generation`}</span><br />
            <span>{`Volume`}</span>
            <strong>{props?.generationVolume}</strong>
          </div>
        </div>
        </>
      )}
      <div className="top_right_personal_dtl_box_sec">
        <div className="top_right_personal_dtl_box text-center">
          <span>{t("sponsor")}</span>
          <strong>{props?.sponsorName}</strong>
        </div>
      </div>
      <div className="top_profile_upgrade_renew_btn_sec">
        {!!moduleStatus?.package_upgrade && (
          <button className="top_profile_upgrade_renew_btn_1">
            {t("upgrade")}
          </button>
        )}
        {!!moduleStatus?.subscription_status && (
          <button className="top_profile_upgrade_renew_btn_1">
            {t("Renew")}
          </button>
        )}
      </div>
    </>
  );
}

export default UserDropdown;
