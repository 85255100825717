import DashboardService from "../../services/dashboard/Dashboard"


export const AppLayout = async () => {
    try {
        const response = await DashboardService.appLayout();
        return response
    } catch (error) {
        return error.message
    }
}

export const DashboardUserProfile = async () => {
    try {
        const response = await DashboardService.dashboardTwo();
        return response
    } catch (error) {
        return error.message
    }
}

export const DashboardCentreSection = async () => {
    try {
        const response = await DashboardService.dashboardOne();
        return response
    } catch (error) {
        return error.message
    }
}

export const GetGraph = async (params) => {
    try {
        const response = await DashboardService.getGraph(params)
        return response
    } catch (error) {
        return error.message
    }
}

export const NotificationData = async () => {
    try {
        const response = await DashboardService.notificationCall()
        return response
    } catch (error) {
        console.log(error.message);
    }
}

