import React, { useEffect, useRef, useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { useSelector } from "react-redux";
import { formatDate } from "../../../utils/formateDate";
import { useTranslation } from "react-i18next";
import anime from "animejs/lib/anime.es.js";

const SponserTreeNode = (props) => {
  const { t } = useTranslation();
  const NoProfile = "/images/user-profile.png";
  const plusIcon = "/images/add-icon.png";

  const [expanded, setExpanded] = useState(true);
  const [hoveredItemId, setHoveredItemId] = useState([]);
  const listRef = useRef(null);

  const setSelectedUserId = props.setSelectedUserId;
  const setDoubleClickedUser = props.setDoubleClickedUser;

  const HandleExpand = (data) => {
    if (data?.children?.length === 0 && data?.attributes?.childrenCount > 0) {
      setSelectedUserId(data?.attributes?.id);
    } else {
      setExpanded(!expanded);
    }
  };

  const handleItemHover = (itemId) => {
    setHoveredItemId(itemId);
  };

  const handleDoubleClick = (data) => {
    setDoubleClickedUser(data?.attributes?.id);
  };

  useEffect(() => {
    if (expanded) {
      anime({
        targets: listRef.current,
        translateY: [`${-20}px`, `${0}px`],
        opacity: [0, 1],
        duration: 500,
        easing: "easeInQuad",
      });
    }
  }, [expanded]);

  const popover = (
    <Popover id="popover">
      <div id="treeview_pop">
        <div className="card">
          <div className="card-img-top">
            <div className="card-img-top_img">
              <img
                src={
                  hoveredItemId?.tooltipData?.profilePic
                    ? hoveredItemId?.tooltipData?.profilePic
                    : NoProfile
                }
                alt="profile photo"
              />
            </div>
            <h5 className="card-title">
              {hoveredItemId?.tooltipData?.username}
            </h5>
            <p className="card-text">
              {hoveredItemId?.tooltipData?.fullName
                ? hoveredItemId?.tooltipData?.fullName
                : "username"}
              <br />
                {hoveredItemId?.tooltipData?.type
                  ? hoveredItemId?.tooltipData?.type
                  : "username"}
            </p>
          </div>
          <div className="card-body">
            <div className="user_detail_tabl">
              <table>
                <tbody>
                  {hoveredItemId?.tooltipData?.tableData &&
                    Object.entries(hoveredItemId.tooltipData.tableData).map(
                      ([key, value]) => (
                        <tr key={key}>
                          <td>{t(key)}</td>
                          <td>
                            {key === "joinDate" ? formatDate(value) : value}
                          </td>
                        </tr>
                      )
                    )}
                </tbody>
              </table>
            </div>
            {hoveredItemId?.tooltipData?.rankDetails &&
              hoveredItemId?.tooltipData?.rankDetails?.name && (
                <a
                  className="btn btn-rank"
                  style={{
                    backgroundColor:
                      hoveredItemId?.tooltipData?.rankDetails?.color,
                  }}
                >
                  {hoveredItemId?.tooltipData?.rankDetails?.name}
                </a>
              )}
          </div>
        </div>
      </div>
    </Popover>
  );

  return (
    <li>
      {props.data?.isPlaceholder ? (
        <>
          <a>
            <div className="member-view-box">
              <div className="member-image">
                <img src={plusIcon} alt="Member" />
              </div>
            </div>
          </a>
        </>
      ) : (
        <>
          <a
            onClick={() => HandleExpand(props.data)}
            onMouseEnter={() => handleItemHover(props.data)}
            onMouseLeave={() => handleItemHover([])}
            onDoubleClick={() => handleDoubleClick(props.data)}
          >
            <div className="member-view-box">
              <OverlayTrigger
                trigger={["hover", "focus"]}
                placement="right"
                overlay={popover}
              >
                <div className="member-image">
                  <img
                    src={
                      props.data?.attributes?.treeIcon === null ||
                      props.data?.attributes?.treeIcon === ""
                        ? NoProfile
                        : props.data?.attributes?.treeIcon
                    }
                    alt="Member"
                  />
                  <div className="member-details">
                    <h3>{props.data?.username}</h3>
                    <div className="member-details-downline-dtl">
                      <div className="member-details-dwnl-bx">
                        {t("count")}:{" "}
                        <strong>{props.data?.attributes?.childrenCount}</strong>
                      </div>
                    </div>
                  </div>
                </div>
              </OverlayTrigger>
            </div>
          </a>
          {props.data?.children?.length > 0 && expanded && (
            <ul ref={listRef}>
              {props.data?.children?.map((child, index) => {
                return (
                  <SponserTreeNode
                    key={index}
                    data={child}
                    plan={props.plan}
                    selectedUserId={props.selectedUserId}
                    setSelectedUserId={props.setSelectedUserId}
                    doubleClickedUser={props.doubleClickedUser}
                    setDoubleClickedUser={props.setDoubleClickedUser}
                  />
                );
              })}
            </ul>
          )}
        </>
      )}
    </li>
  );
};
const SponserTreeComponent = (props) => {
  const mlmPlan = useSelector(
    (state) => state.user?.loginResponse?.moduleStatus?.mlm_plan
  );
  const data = useSelector((state) => state.tree?.sponserTreeList);
  return (
    <SponserTreeNode
      data={data}
      plan={mlmPlan}
      selectedUserId={props.selectedUserId}
      setSelectedUserId={props.setSelectedUserId}
      doubleClickedUser={props.doubleClickedUser}
      setDoubleClickedUser={props.setDoubleClickedUser}
    />
  );
};
export default SponserTreeComponent;
