import React from "react";
import ProductForm from "./ProductForm";

const ProductDetails = () => {
    const productData = [
        {
            category: "Repurchase Category 1",
            productId: "Product 4",
            pv: 50,
        },
    ];

    return (
        <div className="productBodySec">
            {productData.map((product, index) => (
                <div className="row" key={index}>
                    <div className="col-md-4">
                        <div className="repurchaseBg">
                            <h5>Repurchase Pack3</h5>
                            <div className="imgSpaceProduct"></div>
                        </div>
                    </div>
                    <div className="col-md-8">
                        <div className="purchseCartBg">
                            <div className="productIdPvSec">
                                <div className="catagorySec">
                                    <p>
                                        <span>Category:</span> {product.category}
                                    </p>
                                </div>
                                <div className="catagorySec">
                                    <p>
                                        <span>Product Id:</span> {product.productId}
                                    </p>
                                </div>
                                <div className="catagorySec">
                                    <p>
                                        <span>PV:</span> {product.pv}
                                    </p>
                                </div>
                            </div>
                            <ProductForm />
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default ProductDetails