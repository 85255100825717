import React, { useEffect, useMemo, useState } from "react";
import Cookies from "js-cookie";
import { Modal, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ApiHook } from "../../../hooks/apiHook";
import SubmitButton from "../buttons/SubmitButton";
import { toast } from "react-toastify";

const VisitersForm = ({ isVisible, setIsVisible, countries }) => {
  const { t } = useTranslation();
  const demoVisitorAddMutation = ApiHook.CallAddDemoVisitor();
  const resendOtpMutation = ApiHook.CallResendOtp();
  const otpVerifyMutation = ApiHook.CallVerifyOtp();

  const [timeLeft, setTimeLeft] = useState(30);
  const [timerActive, setTimerActive] = useState(false);
  const [showResendButton, setShowResendButton] = useState(false);

  const [show, setShow] = useState(false);
  const [step, setStep] = useState(1);
  const [otpSendMsg, setOtpSendMsg] = useState("");
  const [visitorData, setVisitorData] = useState({
    name: "",
    email: "",
    phone: "",
    countryId: "",
  });
  const [otp, setOtp] = useState();
  const [errorMessage, setErrorMessage] = useState({
    name: null,
    email: null,
    phone: null,
    countryId: null,
  });
  const [otpError, setErrorOtp] = useState();

  const resendOtp = () => {
    const body = {
      visitorId: localStorage.getItem("visitorId"),
    };
    resendOtpMutation.mutateAsync(body, {
      onSuccess: (res) => {
        startTimer();
      },
    });
  };

  const startTimer = () => {
    setTimeLeft(30);
    setTimerActive(true);
    setShowResendButton(false);
  };

  const stopTimer = () => {
    setTimerActive(false);
    setShowResendButton(true);
  };

  useEffect(() => {
    let interval;

    if (timerActive && timeLeft > 0) {
      interval = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    } else if (timeLeft === 0) {
      stopTimer();
    }

    return () => {
      clearInterval(interval);
    };
  }, [timerActive, timeLeft]);

  const isFormValid = () => {
    const phoneNumber = visitorData?.phone || "";
    const isValidPhoneNumber = /^\d{5,}$/.test(phoneNumber);

    return (
      visitorData?.countryId.trim() !== "" &&
      visitorData?.email.trim() !== "" &&
      visitorData?.name.trim() !== "" &&
      isValidPhoneNumber
    );
  };

  const isOtpValid = () => {
    if (otpError === null) {
      return false;
    } else {
      return true;
    }
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setVisitorData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
    setErrorMessage((prevData) => ({
      ...prevData,
      [id]: null,
    }));
    if (value === null || value === "") {
      setErrorMessage((prev) => ({
        ...prev,
        [id]: "*Required",
      }));
    }
    if (id === "phone") {
      const digitCount = (visitorData?.phone.match(/\d/g) || []).length;
      if (digitCount < 4) {
        setErrorMessage((prevData) => ({
          ...prevData,
          phone: t("min_length_of_5"),
        }));
      }
    }
  };

  const handleOtpField = (e) => {
    const { id, value } = e.target;
    setOtp(value);
    setErrorOtp(null);
    if (value === null || value === "") {
      setErrorOtp("*Required");
    }
  };

  const handleNextStep = () => {
    demoVisitorAddMutation.mutateAsync(visitorData, {
      onSuccess: (response) => {
        if(response?.status) {
          setStep(2);
          setOtpSendMsg(response?.data?.message);
          localStorage.setItem("visitorId", response?.data?.visitorId);
          startTimer();
        }
      },
    });
  };

  const handleVerifyOTP = () => {
    const data = {
      otp: otp,
      visitorId: localStorage.getItem("visitorId"),
    };
    otpVerifyMutation.mutateAsync(data, {
      onSuccess: (res) => {
        if(res?.status) {
          toast.success(res?.data);
          setIsVisible(false);
          Cookies.set("visitorID", data.visitorId, { expires: 1 });
        }
      },
    });
  };

  const isFormValidValue = useMemo(() => isFormValid(), [visitorData]);

  return (
    <Modal show={isVisible} onHide={() => setShow(false)} size="lg">
      <Modal.Header>
        <Modal.Title>Please fill in your details to continue</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row align-items-center">
          <div className="col-md-6">
            <img
              src="/images/lead-vector.png"
              className="lead_vectr_img"
              alt=""
              style={{ maxWidth: "100%" }}
            />
          </div>
          <div className="col-md-6">
            {step === 1 ? (
              <div className="step_1">
                <Form>
                  <Form.Group className="mb-3">
                    <Form.Label>Name*</Form.Label>
                    <Form.Control
                      id="name"
                      type="text"
                      placeholder="Name"
                      value={visitorData.name}
                      onChange={handleChange}
                      required
                    />
                    {errorMessage.name && (
                      <div style={{ color: "red" }}>{t(errorMessage.name)}</div>
                    )}
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Email*</Form.Label>
                    <Form.Control
                      id="email"
                      type="text"
                      placeholder="Email"
                      value={visitorData.email}
                      onChange={handleChange}
                      required
                    />
                    {errorMessage.email && (
                      <div style={{ color: "red" }}>
                        {t(errorMessage.email)}
                      </div>
                    )}
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Phone*</Form.Label>
                    <Form.Control
                      id="phone"
                      type="number"
                      placeholder="Phone"
                      value={visitorData.phone}
                      onChange={handleChange}
                      required
                    />
                    {errorMessage.phone && (
                      <div style={{ color: "red" }}>
                        {t(errorMessage.phone)}
                      </div>
                    )}
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Country*</Form.Label>
                    <Form.Control
                      as="select"
                      id="countryId"
                      onChange={handleChange}
                      value={visitorData.countryId}
                    >
                      <option value="" disabled>
                        Select a country
                      </option>
                      {countries?.map((country) => (
                        <option key={country.id} value={country.id}>
                          {country.name}
                        </option>
                      ))}
                    </Form.Control>
                    {errorMessage?.countryId && (
                      <div style={{ color: "red" }}>
                        {t(errorMessage?.countryId)}
                      </div>
                    )}
                  </Form.Group>
                </Form>
              </div>
            ) : (
              <div className="step_2">
                {otpSendMsg && (
                  <div style={{ color: "green" }}>{otpSendMsg}</div>
                )}
                <Form.Group>
                  <Form.Label>OTP</Form.Label>
                  <Form.Control
                    id="otp"
                    type="text"
                    placeholder="OTP"
                    value={otp}
                    onChange={(e) => handleOtpField(e)}
                  />
                  {otpError && (
                    <div style={{ color: "red" }}>{t(otpError)}</div>
                  )}
                </Form.Group>
                {timerActive ? (
                  <p>
                    {"Resend code in " +
                      Math.floor(timeLeft / 60) +
                      ":" +
                      (timeLeft % 60 < 10 ? "0" : "") +
                      (timeLeft % 60)}
                  </p>
                ) : (
                  showResendButton && (
                    <span onClick={resendOtp} style={{ cursor: "pointer" }}>
                      Resend Code
                    </span>
                  )
                )}
              </div>
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {step === 1 ? (
          <SubmitButton
            isSubmitting={!isFormValidValue}
            click={handleNextStep}
            text={demoVisitorAddMutation.isLoading ? "Sending OTP..." : "Next"}
            className={"btn btn-primary submit_btn"}
          />
        ) : (
          <SubmitButton
            isSubmitting={isOtpValid()}
            click={handleVerifyOTP}
            text={
              demoVisitorAddMutation.isLoading ? "Verifying..." : "Verify OTP"
            }
            className={"btn btn-primary submit_btn"}
          />
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default VisitersForm;
