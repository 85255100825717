import React from 'react';
import CurrencyConverter from '../../Currency/CurrencyConverter';
import { useTranslation } from 'react-i18next';
const QuickBalance = ({ tiles, currency, conversionFactor }) => {
  const { t } = useTranslation();
  return (
    <div className="quick_balance_section">
      <div className="quick_balance_section_row">
        <div className="quick_balance_Box">
          <div className="quick_balance_Box_ico">
            <img src='/images/balance_ewallet_ico.svg' alt="" />
          </div>
          <div className="quick_balance_Box_cnt">
            <span>{t('teamBonusTile')}</span>
            <strong>{`${currency?.symbolLeft} ${CurrencyConverter(tiles?.ewallet,conversionFactor)}`}</strong>
      
          </div>
        </div>

        <div className="quick_balance_Box">
          <div className="quick_balance_Box_ico">
            <img src='/images/balance_commision_ico.svg' alt="" />
          </div>
          <div className="quick_balance_Box_cnt">
            <span>{t('clusterBonusTile')}</span>
            <strong>{`${currency?.symbolLeft} ${CurrencyConverter(tiles?.commission,conversionFactor)}`}</strong>
            <div className={`tp_comparison ${tiles?.commissionSign}`}>
              <span>
                {`${tiles?.commissionSign === 'up' ? '+' : '-'}${tiles?.commissionPercentage}%`}
              </span>
              <i className={`fa-solid ${tiles?.commissionSign === 'up' ? 'fa-arrow-up' : 'fa-arrow-down'}`}></i>
            </div>
          </div>
        </div>

        <div className="quick_balance_Box">
          <div className="quick_balance_Box_ico">
            <img src='/images/balance_commision_ico.svg' alt="" />
          </div>
          <div className="quick_balance_Box_cnt">
            <span>{t('retailBonusTile')}</span>
            <strong>{`${currency?.symbolLeft} ${CurrencyConverter(tiles?.totalCredit,conversionFactor)}`}</strong>
            <div className={`tp_comparison ${tiles?.creditSign}`}>
              <span>
                {`${tiles?.creditSign === 'up' ? '+' : '-'}${tiles?.totalCreditPercentage}%`}
              </span>
              <i className={`fa-solid ${tiles?.creditSign === 'up' ? 'fa-arrow-up' : 'fa-arrow-down'}`}></i>
            </div>
          </div>
        </div>

        <div className="quick_balance_Box">
          <div className="quick_balance_Box_ico">
            <img src='/images/balance_pending_ico.svg' alt="" />
          </div>
          <div className="quick_balance_Box_cnt">
            <span>{t('associateCountTile')}</span>
            <strong>{`${tiles?.totalDebit}`}</strong>
            <div className={`tp_comparison ${tiles?.debitSign}`}>
              {/* <span>
                {`${tiles?.debitSign === 'up' ? '+' : '-'}${tiles?.totalDebitPercentage}%`}
              </span>
              <i className={`fa-solid ${tiles?.debitSign === 'up' ? 'fa-arrow-up' : 'fa-arrow-down'}`}></i> */}
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};

export default QuickBalance;
