import {
  GenealogyService,
  TreeViewService,
  SponserTreeService,
  DownlineMembersService,
  ReferralMembersService,
} from "../../services/tree/network";
export const GenealogyActions = {
  getTreelist: async (userId, userName) => {
    try {
      const response = await GenealogyService.getTreelist(userId, userName);
      return response;
    } catch (error) {
      return error.message;
    }
  },
  getUnilevelMore: async (fatherId, position) => {
    try {
      const response = await GenealogyService.getUnilevelMore(
        fatherId,
        position
      );
      return response;
    } catch (error) {
      return error.message;
    }
  },
};

export const TreeViewActions = {
  getTreelist: async (userId) => {
    try {
      const response = await TreeViewService.getTreelist(userId);
      return response;
    } catch (error) {
      return error.message;
    }
  },
  getTreelistSponsor: async (userId) => {
    try {
      const response = await TreeViewService.getTreelistSponsor(userId);
      return response;
    } catch (error) {
      return error.message;
    }
  },
};

export const SponserTreeActions = {
  getTreelist: async (userId, userName) => {
    try {
      const response = await SponserTreeService.getTreelist(userId, userName);
      return response;
    } catch (error) {
      return error.message;
    }
  },
};
export const downlineMembersActions = {
  getDownlineMembers: async (month, page, itemsPerPage,sortAsc) => {
    try {
      const response = await DownlineMembersService.callDownline(
        month,
        page,
        itemsPerPage,
        sortAsc
      );
      return response;
    } catch (error) {
      return error.message;
    }
  },
  getOrders: async (start, end, page, itemsPerPage) => {
    try {
      const response = await DownlineMembersService.callOrders(
        start,
        end,
        page,
        itemsPerPage
      );
      return response;
    } catch (error) {
      return error.message;
    }
  },
  getDownlineheaders: async () => {
    try {
      const response = await DownlineMembersService.callHeader();
      return response;
    } catch (error) {
      return error.message;
    }
  },
  getOrderheaders: async () => {
    try {
      const response = await DownlineMembersService.callHeaderOrder();
      return response;
    } catch (error) {
      return error.message;
    }
  },
};

export const ReferralMembersActions = {
  getReferralmembers: async (level, page, itemsPerPage, start, end,sort) => {
    try {
      const response = await ReferralMembersService.callReferral(
        level,
        page,
        itemsPerPage,
        start,
        end,
        sort
      );
      return response;
    } catch (error) {
      return error.message;
    }
  },
  getRferralHeader: async () => {
    try {
      const response = await ReferralMembersService.callHeader();
      return response;
    } catch (error) {
      return error.message;
    }
  },
};
