import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  termsAndPolicy: { data: {} },
};

const userSlice = createSlice({
  name: 'termsAndPolicy',
  initialState,
  reducers: {
    setTermsAndPolicy: (state, action) => {
      state.termsAndPolicy = {terms: action.payload?.terms,policy:action.payload?.policy};
    },
  },
});

export const { setTermsAndPolicy } = userSlice.actions;

export default userSlice.reducer;
