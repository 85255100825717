import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  appLayout: { data: {} },
  dashboardOne: { data: {} },
};

const userSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setAppLayout: (state, action) => {
      state.appLayout = action.payload;
    },
    setDashboardOne: (state, action) => {
      state.dashboardOne = action.payload;
    },
  },
});

export const { setAppLayout, setDashboardOne } = userSlice.actions;

export default userSlice.reducer;
