import { Navigate } from "react-router-dom";
import MainDashboard from "../views/dashboard/MainDashboard";
import AuthLayout from "../Layouts/AuthLayout";
import EwalletLayout from "../views/ewallet/MainEwallet";
import PayoutLayout from "../views/payout/MainPayout";
import RegisterLayout from "../views/register/MainRegister";
import ProfileLayout from "../views/profile/MainProfile";
import GenealogyTree from "../views/tree/GenealogyTree";
import TreeView from "../views/tree/TreeView";
import EpinLayout from "../views/epin/MainEpin";
import ShoppingLayout from "../views/shopping/ShoppingLayout";
import ProductDetailsLayout from "../views/shopping/ProductDetailsLayout";
import CheckoutLayout from "../views/shopping/CheckoutLayout";
import Faqs from "../components/Tools/Faqs";
import News from "../components/Tools/News";
import DownlineMembers from "../views/tree/DownlineMembers";
import SponserTree from "../views/tree/SponserView";
import ReferralMembers from "../views/tree/ReferralMembers";
import Leads from "../views/Crm/Leads";
import ComingSoon from "../components/Common/ComingSoon";
import BackToOffice from "../components/Auth/BackToOffice";
import RegisterComplete from "../views/register/RegisterComplete";
import KycDetails from "../components/Profile/KycDetails";
import ReplicaSite from "../views/Tools/ReplicaSite";
import LeadsForm from "../components/Crm/LeadsForm";
import DownloadMaterials from "../views/Tools/DownloadMaterials";
import ReplicaLayout from "../Layouts/ReplicaLayout";
import MainReplica from "../views/replica/MainReplica";
import ReplicaRegisterLayout from "../views/replica/MainReplicaRegister";
import { ForgotPasswordForm } from "../components/Auth/ForgotPassword";
import TreeViewSponsor from "../views/tree/TreeViewSponsor";
import Orders from "../views/orders/Orders";

const publicRoutes = [
  {
    path: "/",
    element: <Navigate to="/login" replace />,
  },
  {
    path: "/login/:adminUsername?/:username?",
    element: <AuthLayout />,
  },
  {
    path: "/oc-login/?:string?:db_prefix?",
    element: <BackToOffice />,
  },
  {
    path: "/lcp/:username?/:hash?",
    element: <LeadsForm />,
  },
  // {
  //   path: '/replica/:leg/:username/:hashKey',
  //   element: <ReplicaLayout><MainReplica /></ReplicaLayout>
  // },
  {
    path: "/replica/:leg/:username/:hashKey",
    // path: '/replica-register',
    element: (
      <ReplicaLayout>
        <ReplicaRegisterLayout />
      </ReplicaLayout>
    ),
  },
  {
    path: "/forgot-password/:hashKey",
    element: <ForgotPasswordForm />,
  },
];

const privateRoutes = [
  {
    path: "/dashboard",
    element: <MainDashboard />,
  },
  {
    path: "/networks",
    element: <Navigate to="/genealogy-tree" replace />,
  },
  {
    path: "/e-wallet",
    element: <EwalletLayout />,
  },
  {
    path: "/e-pin",
    element: <EpinLayout />,
  },
  {
    path: "/payout",
    element: <PayoutLayout />,
  },
  {
    path: "/genealogy-tree",
    element: <GenealogyTree />,
  },
  {
    path: "/sponsor-tree",
    element: <SponserTree />,
  },
  {
    path: "/tree-view",
    element: <TreeView />,
  },
  {
    path: "/cluster-tree-view",
    element: <TreeViewSponsor />,
  },
  {
    path: "/downline-members",
    element: <DownlineMembers />,
  },
  {
    path: "/order-details",
    element: <Orders />,
  },
  {
    path: "/referral-members",
    element: <ReferralMembers />,
  },
  {
    path: "/register/:parent?/:position?",
    element: <RegisterLayout />,
  },
  {
    path: "/profile",
    element: <ProfileLayout />,
  },
  {
    path: "/shopping",
    element: <ShoppingLayout />,
  },
  {
    path: "/product-details",
    element: <ProductDetailsLayout />,
  },
  {
    path: "/checkout",
    element: <CheckoutLayout />,
  },
  {
    path: "/faqs",
    element: <Faqs />,
  },
  {
    path: "/news/:newsId?",
    element: <News />,
  },
  {
    path: "/leads",
    element: <Leads />,
  },
  {
    path: "/settings",
    element: <ComingSoon />,
  },
  {
    path: "/downloads",
    element: <ComingSoon />,
  },
  {
    path: "/mail-box",
    element: <ComingSoon />,
  },
  {
    path: "/support-center",
    element: <ComingSoon />,
  },
  {
    path: "/crm",
    element: <ComingSoon />,
  },
  {
    path: "/download-materials",
    element: <DownloadMaterials />,
  },
  {
    path: "/replica-site",
    element: <ReplicaSite />,
  },
  {
    path: "/promotion-tools",
    element: <ComingSoon />,
  },
  {
    path: "/add-lead",
    element: <ComingSoon />,
  },
  {
    path: "/view-lead",
    element: <ComingSoon />,
  },
  {
    path: "/graph",
    element: <ComingSoon />,
  },
  {
    path: "/registration-complete/:username?",
    element: <RegisterComplete />,
  },
  {
    path: "/kyc-details",
    element: <KycDetails />,
  },
];

export { privateRoutes, publicRoutes };
