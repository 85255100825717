import { Fragment, React, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import {
  loginPasswordValidator,
  validateAge,
  loginUsernameValidator,
  passwordRules,
} from "../../Validator/register";
import PasswordChecklist from "react-password-checklist";
import { ApiHook } from "../../hooks/apiHook";
import { BASE_URL } from "../../config/config";

const ReplicaRegisterForm = ({
  activeStep,
  setActiveStep,
  handleToggleRegisterModal,
  animateStepTransition,
  data,
}) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState("");
  const [states, setStates] = useState([]);
  const [submitButtonActive, setSubmitButtonActive] = useState(true);
  const [file, setFile] = useState(null);
  const [fileResponse, setFileResponse] = useState({
    success: null,
    error: null,
  });

  const {
    register,
    setValue,
    trigger,
    watch,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    setValue("position", data.position);
    setValue("username", data.loginInformation[0].value);
  }, [data]);

  const formValues = watch();

  const steps = ["Step 1", "Step 2", "Step 3", "Step 4", "Step 5"];

  // ------------------------------------------- API ------------------------------------------
  const checkField = ApiHook.CallReplicaFieldCheck();
  const checkMobile = ApiHook.CallMobileCheck();
  const Upload = ApiHook.CallReplicaBankRecieptUpload(
    "register/replica",
    formValues?.username,
    data?.sponsorData?.id,
    setSubmitButtonActive,
    setValue,
    setFileResponse
  );
  const replicaRegisterMutation = ApiHook.CallReplicaRegisterPost();

  // ---------------------------------- Functions ------------------------------------

  const handleNext = async () => {
    let isValid;
    if (
      activeStep === 3 &&
      !checkField.data?.data?.status &&
      checkField.data?.data?.code === 1117
    ) {
      isValid = false;
    } else if (
      activeStep === 2 &&
      !checkField.data?.data?.status &&
      checkField.data?.data?.code === 1117
    ) {
      isValid = false;
    } else if (
      activeStep === 2 &&
      !checkMobile.data?.data?.status &&
      checkMobile.data?.data?.code === 1117
    ) {
      isValid = false;
    } else {
      isValid = await trigger();
    }
    if (isValid) {
      const nextStep = activeStep + 1;
      animateStepTransition(nextStep);
      setActiveStep(nextStep);
    }
  };

  const handleBack = () => {
    const prevStep = activeStep - 1;
    animateStepTransition(prevStep);
    setActiveStep(prevStep);
  };

  const handleSubmit = () => {
    if (data?.sponsorData?.username) {
      setValue("placement", data?.sponsorData?.username);
      formValues.placement = data?.sponsorData?.username;
      setValue("referralId", data?.sponsorData?.id);
      formValues.referralId = data?.sponsorData?.id;
    }
    const response = replicaRegisterMutation.mutate(formValues);
  };

  const handleProductClick = (productId, productName, pv, price) => {
    const totalAmount = Number(price) + JSON.parse(data?.regFee);
    setValue("totalAmt", totalAmount.toFixed(2));
    setValue(
      "product",
      { id: productId, name: productName, price: price },
      { shouldValidate: true }
    );
    setValue("pv", pv);
    setValue("totalAmount", totalAmount.toFixed(2));
    setValue("regAmount", data?.regFee);
  };

  const handlePaymentTabClick = (tabId) => {
    setActiveTab(tabId);
    if (tabId === 3) {
      setSubmitButtonActive(false);
    } else {
      setSubmitButtonActive(true);
    }
    setValue("paymentType", tabId, { shouldValidate: true }); // Set the selected payment
    if (!data?.modStatus?.productStatus) {
      setValue("totalAmount", data?.regFee); // regAmount is added to total amount. If there is no product
    }
  };

  const handleEmailCheck = async (item) => {
    const { name, value } = item;
    checkField.mutate({ field: name, value: value });
  };
  const handleMobileCheck = async (item) => {
    const { name, value } = item;
    if (name === "mobile") {
      checkMobile.mutate(value);
    }
  };
  const handleUsernameCheck = async (item) => {
    const { name, value } = item;
    // setFieldCheckData((prevValue) => ({
    //   ...prevValue,
    //   field: name,
    //   value: value,
    // }));
    // setFieldCheck(1);
  };

  const handleCustomField = (id, value) => {
    if (formValues.customFields?.length > 0) {
      const existingIndex = formValues.customFields.findIndex(
        (item) => item.id === id
      );
      if (existingIndex !== -1) {
        const updatedCustomField = [...formValues.customFields];
        updatedCustomField[existingIndex] = { id: id, value: value };
        setValue("customFields", updatedCustomField);
      } else {
        setValue("customFields", [
          ...formValues.customFields,
          { id: id, value: value },
        ]);
      }
    } else {
      setValue("customFields", [{ id: id, value: value }]);
    }
  };

  const handleFileChange = (event) => {
    setFileResponse({
      success: null,
      error: null,
    });
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
  };

  const handleUpload = () => {
    if (file) {
      Upload.mutate(file);
    }
  };

  const handleCountry = (selectedCountry, statesData) => {
    if (selectedCountry) {
      setValue("country", selectedCountry);
      formValues.country = selectedCountry;
    }
    statesData?.map((value) => {
      if (parseInt(selectedCountry) === value.id) {
        setStates(value.States);
      }
    });
  };

  return (
    <div className="main-content-regsiter" style={{ margin: "65px" }}>
      <div className="row justify-content-center pt-0 p-4" id="wizardRow">
        <div className="col-md-12 text-center">
          <div className="wizard-form py-4 my-2">
            <ul id="progressBar" className="progressbar px-lg-5 px-0">
              {steps.map((step, index) => (
                <li
                  key={`step-${index + 1}`}
                  id={`progressList-${index + 1}`}
                  className={`d-inline-block w-20 position-relative text-center float-start progressbar-list ${
                    index <= activeStep - 1 ? "active" : ""
                  }`}
                >
                  {step}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div id="animation">
        {activeStep === 1 && (
          <div className="row row_top justify-content-center" id="cardSection">
            <div className="col-lg-12 col-md-12">
              <div className="regsiter_step_1_view">
                <div className="row">
                  <div className="col-md-12">
                    <div className="right_product_selection_bx">
                      <div className="regsiter_step_1_view_left_sec_head">
                        {data?.registrationSteps &&
                          t(
                            `${data?.registrationSteps[activeStep - 1]?.label}`
                          )}
                      </div>
                      <div className="right_product_lsting_section">
                        <div className="row">
                          {!!data?.regData && (
                            <span>
                              {t("reg_amount_desc", {
                                amount: `$ ${data?.regData}`,
                              })}
                            </span>
                          )}
                          {data?.regPack?.map((product, index) => (
                            <div className="col-md-4" key={index}>
                              <div
                                className={`right_product_box card ${
                                  formValues.product?.id === product.id
                                    ? "active-card"
                                    : ""
                                }`}
                                {...register("product")}
                                onClick={() =>
                                  handleProductClick(
                                    product.id,
                                    product.name,
                                    product.pairValue,
                                    product.price
                                  )
                                }
                              >
                                <div className="right_product_box_image">
                                  <img
                                    src={
                                      product?.image ?? "/images/product1.jpg"
                                    }
                                    alt=""
                                  />
                                </div>
                                <div className="right_product_box__dtails">
                                  <div className="right_product_box__head">
                                    {/* {product?.name} */}{" "}
                                  </div>
                                  {/* <div className="right_product_box__dsc">
                                    <strong>{`$ ${product?.price}`}</strong>
                                    <br />
                                    {"PV - "}
                                    {product?.pairValue}
                                  </div> */}
                                </div>
                                <button
                                  type="button"
                                  className="btn text-white float-end next mt-4 rounded-3 bg-color-info"
                                  onClick={() => handleNext()}
                                >
                                  Register
                                </button>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {activeStep === 2 && (
          <div className="row row_top justify-content-center form-business">
            {/* {data?.modStatus?.mlmPlan === "Binary" && ( */}
            <>
              {/* <div className="regsiter_step_1_view_left_sec_head">
                {t("placement")}
              </div> */}
              {/* <div className="regsiter_second_step_bx reg_select_pos">
                <div className="regsiter_step_1_view_left_btn_row mt-0">
                  <label
                    className={`regsiter_step_1_view_left_btn ${
                      formValues.position === "L" ? "active" : ""
                    }`}
                  >
                    <input
                      type="radio"
                      name="position"
                      value={"L"}
                      {...register("position", {
                        required: true,
                      })}
                    />
                    {t("left")}
                  </label>
                  <label
                    className={`regsiter_step_1_view_left_btn ${
                      formValues.position === "R" ? "active" : ""
                    }`}
                  >
                    <input
                      type="radio"
                      name="position"
                      value={"R"}
                      checked={formValues.position ?? ""}
                      {...register("position", {
                        required: true,
                      })}
                    />
                    {t("right")}
                  </label>
                </div>
                {errors["position"] && formValues.position === null && (
                  <span className="error-message-validator">
                    {t("this_field_is_required")}
                  </span>
                )}
              </div> */}
            </>
            {/* )} */}
            <div className="regsiter_step_1_view_left_sec_head">
              {data?.registrationSteps &&
                t(`${data?.registrationSteps[activeStep - 1]?.label}`)}
            </div>
            <div className="regsiter_second_step_bx">
              <div className="row">
                {data?.contactInformation?.contactField?.map((item, index) => (
                  <div className="col-md-6" key={index}>
                    <div className="regsiter_form_box">
                      <label htmlFor="">
                        {t(`${item.code}`)}
                        <span className="text-danger" hidden={!item.required}>
                          ٭
                        </span>
                      </label>
                      {item.type === "text" &&
                        item.code !== "state" &&
                        !item.options && (
                          <input
                            name={item.code}
                            type="text"
                            className={`form-control ${
                              errors[item.code] ? "error-field" : ""
                            }`}
                            placeholder={item.placeholder}
                            {...register(item.code, {
                              required: {
                                value: item.required,
                                message: t("this_field_is_required"),
                              },
                              pattern: {
                                value: /^[A-Za-z0-9\s]+$/,
                                message: t("invalid_format"),
                              },
                            })}
                            onBlur={async () => await trigger(item.code)}
                          />
                        )}
                      {item.type === "email" && (
                        <>
                          <input
                            name={item.code}
                            type="email"
                            className={`form-control ${
                              (!checkField.data?.data?.status &&
                                checkField.data?.data?.code === 1117) ||
                              errors[item.code]
                                ? "error-field"
                                : ""
                            }`}
                            placeholder={item.placeholder}
                            {...register("email", {
                              required: {
                                value: item.required,
                                message: t("this_field_is_required"),
                              },
                              pattern: {
                                value:
                                  /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]+$/,
                                message: t("invalid_email_format"),
                              },
                            })}
                            onChangeCapture={async (e) => {
                              await handleEmailCheck(e.target);
                            }}
                            onBlur={async () => await trigger(item.code)}
                          />
                          {!checkField.data?.data?.status &&
                            checkField.data?.data?.code === 1117 && (
                              <span className="error-message-validator">
                                {t("email_exists")}
                              </span>
                            )}
                        </>
                      )}
                      {item.type === "date" && (
                        <>
                          <input
                            name={item.code}
                            type="date"
                            className={`form-control ${
                              errors[item.code] ? "error-field" : ""
                            }`}
                            placeholder={item.placeholder}
                            {...register(item.code, {
                              required: {
                                value: item.required,
                                message: t("this_field_is_required"),
                              },
                              validate: (value) => validateAge(value, t),
                            })}
                            onBlur={async () => await trigger(item.code)}
                          />
                          {errors.date_of_birth && (
                            <span className="error-message-validator">
                              {errors.date_of_birth.message}
                            </span>
                          )}
                        </>
                      )}
                      {item.type === "number" && (
                        <>
                          <input
                            name={item.code}
                            type="number"
                            className={`form-control ${
                              errors[item.code] ? "error-field" : ""
                            }`}
                            placeholder={item.placeholder}
                            {...register(item.code, {
                              required: {
                                value: item.required,
                                message: t("this_field_is_required"),
                              },
                              minLength: {
                                value: 5,
                                message: t("min_length_of_5"),
                              },
                            })}
                            onBlur={async () => await trigger(item.code)}
                            onChangeCapture={async (e) => {
                              handleMobileCheck(e.target);
                            }}
                          />
                          {!checkMobile.data?.data?.status &&
                            item.code === "mobile" &&
                            checkMobile.data?.data?.code === 1117 && (
                              <span className="error-message-validator">
                                {t("mobile_exists")}
                              </span>
                            )}
                        </>
                      )}
                      {item.type === "text" && item.code === "gender" && (
                        <select
                          name={item.code}
                          className={`form-control ${
                            errors[item.code] ? "error-field" : ""
                          }`}
                          {...register(item.code, {
                            required: {
                              value: item.required,
                              message: t("this_field_is_required"),
                            },
                          })}
                          onBlur={async () => await trigger(item.code)}
                        >
                          {item?.options?.map((option, optionIndex) => (
                            <option key={optionIndex} value={option.value}>
                              {option.title}
                            </option>
                          ))}
                        </select>
                      )}
                      {item.type === "text" && item.code === "country" && (
                        <select
                          name={item.code}
                          className={`form-control ${
                            errors[item.name] ? "error-field" : ""
                          }`}
                          {...register(item.code, {
                            required: {
                              value: item.required,
                              message: t("this_field_is_required"),
                            },
                          })}
                          onChange={(e) =>
                            handleCountry(e.target.value, item.options)
                          }
                          onBlur={async () => await trigger(item.code)}
                        >
                          <option>{t("select_the_country")}</option>
                          {item?.options?.map((option, optionIndex) => (
                            <option key={optionIndex} value={option.id}>
                              {option.name}
                            </option>
                          ))}
                        </select>
                      )}
                      {item.type === "text" && item.code === "state" && (
                        <select
                          name={item.name}
                          className={`form-control ${
                            errors[item.name] ? "error-field" : ""
                          }`}
                          {...register(item.code, {
                            required: {
                              value: item.required,
                              message: t("this_field_is_required"),
                            },
                          })}
                          onBlur={async () => await trigger(item.name)}
                        >
                          {states?.map((option, optionIndex) => (
                            <option key={optionIndex} value={option.id}>
                              {option.name}
                            </option>
                          ))}
                        </select>
                      )}
                      {errors[item.code] && !errors.date_of_birth && (
                        <span className="error-message-validator">
                          {errors[item.code].message}
                        </span>
                      )}
                    </div>
                  </div>
                ))}
                {data?.contactInformation?.customField?.map((item, index) => (
                  <div className="col-md-6" key={index}>
                    <div className="regsiter_form_box">
                      <label htmlFor="">
                        {item.value}{" "}
                        <span className="text-danger" hidden={!item.required}>
                          ٭
                        </span>
                      </label>
                      {item.type === "text" && !item.options && (
                        <input
                          name={item.code}
                          type="text"
                          className={`form-control ${
                            errors[item.code] ? "error-field" : ""
                          }`}
                          placeholder={item.placeholder}
                          {...register(item.code, {
                            required: {
                              value: item.required,
                              message: t("this_field_is_required"),
                            },
                            pattern: {
                              value: /^[A-Za-z0-9]+$/,
                              message: t("invalid_format"),
                            },
                          })}
                          onBlur={async () => {
                            await trigger(item.code);
                            handleCustomField(item.id, formValues[item.code]);
                          }}
                        />
                      )}
                      {item.type === "email" && (
                        <>
                          <input
                            name={item.code}
                            type="email"
                            className={`form-control ${
                              (!checkField.data?.data?.status &&
                                checkField.data?.data?.code === 1117) ||
                              errors[item.code]
                                ? "error-field"
                                : ""
                            }`}
                            placeholder={item.placeholder}
                            {...register("email", {
                              required: {
                                value: item.required,
                                message: t("this_field_is_required"),
                              },
                              pattern: {
                                value:
                                  /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]+$/,
                                message: t("invalid_email_format"),
                              },
                            })}
                            onChangeCapture={async (e) => {
                              handleEmailCheck(e.target);
                              handleCustomField(item.id, formValues[item.code]);
                            }}
                          />
                          {!checkField.data?.data?.status &&
                            checkField.data?.data?.code === 1117 && (
                              <span className="error-message-validator">
                                {t("email_exists")}
                              </span>
                            )}
                        </>
                      )}
                      {item.type === "date" && (
                        <>
                          <input
                            name={item.code}
                            type="date"
                            className={`form-control ${
                              errors[item.code] ? "error-field" : ""
                            }`}
                            placeholder={item.placeholder}
                            {...register(item.code, {
                              required: {
                                value: item.required,
                                message: t("this_field_is_required"),
                              },
                              validate: (value) => validateAge(value, t),
                            })}
                            onBlur={async () => {
                              await trigger(item.code);
                              handleCustomField(item.id, formValues[item.code]);
                            }}
                          />
                          {errors.date_of_birth && (
                            <span className="error-message-validator">
                              {errors.date_of_birth.message}
                            </span>
                          )}
                        </>
                      )}
                      {item.type === "textarea" && (
                        <textarea
                          name={item.code}
                          className={`form-control ${
                            errors[item.code] ? "error-field" : ""
                          }`}
                          placeholder={item.placeholder}
                          {...register(item.code, {
                            required: {
                              value: item.required,
                              message: t("this_field_is_required"),
                            },
                          })}
                          onBlur={async () => {
                            await trigger(item.code);
                            handleCustomField(item.id, formValues[item.code]);
                          }}
                        />
                      )}
                      {item.type === "number" && (
                        <input
                          name={item.code}
                          type="number"
                          className={`form-control ${
                            errors[item.code] ? "error-field" : ""
                          }`}
                          placeholder={item.placeholder}
                          {...register(item.code, {
                            required: {
                              value: item.required,
                              message: t("this_field_is_required"),
                            },
                            minLength: {
                              value: 5,
                              message: t("min_length_of_5"),
                            },
                          })}
                          onBlur={async () => {
                            await trigger(item.code);
                            handleCustomField(item.id, formValues[item.code]);
                          }}
                        />
                      )}
                      {item.type === "text" &&
                        (item.code === "gender" ||
                          item.code === "country" ||
                          item.code === "state") && (
                          <select
                            name={item.code}
                            className={`form-control ${
                              errors[item.code] ? "error-field" : ""
                            }`}
                            {...register(item.code, {
                              required: {
                                value: item.required,
                                message: t("this_field_is_required"),
                              },
                            })}
                            onBlur={async () => {
                              await trigger(item.code);
                              handleCustomField(item.id, formValues[item.code]);
                            }}
                          >
                            {item?.options?.map((option, optionIndex) => (
                              <option key={optionIndex} value={option.code}>
                                {option.title}
                              </option>
                            ))}
                          </select>
                        )}
                      {errors[item.code] && !errors.date_of_birth && (
                        <span className="error-message-validator">
                          {errors[item.code].message}
                        </span>
                      )}
                    </div>
                  </div>
                ))}
                <div className="col-md-12">
                  <button
                    type="button"
                    className="btn btn-dark text-white float-start back mt-4 rounded-3 bg-color-back"
                    onClick={handleBack}
                  >
                    Back
                  </button>
                  <button
                    type="button"
                    className="btn text-white float-end next mt-4 rounded-3 bg-color-info"
                    onClick={handleNext}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {activeStep === 3 && (
          <div className="row row_top justify-content-center form-business">
            <div className="regsiter_step_1_view_left_sec_head">
              {data?.registrationSteps &&
                t(`${data?.registrationSteps[activeStep - 1]?.label}`)}
            </div>
            <div className="regsiter_second_step_bx">
              <div className="row">
                {data?.loginInformation.map((item, index) => (
                  <div className="col-md-12" key={index}>
                    <div className="regsiter_form_box">
                      <label htmlFor="">
                        {t(item.code)} <span className="text-danger">٭</span>
                      </label>
                      {item.type === "text" && (
                        <>
                          <input
                            name={item.code}
                            type={item.type}
                            disabled={item.value != ""}
                            className={`form-control ${
                              (!checkField.data?.data?.status &&
                                checkField.data?.data?.code === 1117) ||
                              errors[item.code]
                                ? "error-field"
                                : ""
                            }`}
                            placeholder={item.placeholder}
                            {...register(
                              item.code
                              // loginUsernameValidator(item, t)
                            )}
                            onChangeCapture={async (e) => {
                              await handleUsernameCheck(e.target);
                            }}
                          />
                          {!checkField.data?.data?.status &&
                            checkField.data?.data?.code === 1117 && (
                              <span className="error-message-validator">
                                {t("username_exists")}
                              </span>
                            )}
                        </>
                      )}
                      {item.type === "password" && (
                        <>
                          <input
                            name={item.code}
                            type={item.type}
                            style={{ marginBottom: "8px" }}
                            className={`form-control ${
                              errors[item.code] ? "error-field" : ""
                            }`}
                            placeholder={item.placeholder}
                            {...register(
                              item.code,
                              loginPasswordValidator(item, t)
                            )}
                            onBlur={async () => await trigger(item.code)}
                          />
                          <PasswordChecklist
                            rules={passwordRules(item.validation)}
                            minLength={item.validation.minLength}
                            value={watch("password", "")}
                          />
                        </>
                      )}
                      {errors[item.code] && (
                        <span className="error-message-validator">
                          {errors[item.code].message}
                        </span>
                      )}
                    </div>
                  </div>
                ))}
                <div className="col-md-12">
                  <div className="regsiter_form_box">
                    <label className="d-flex" htmlFor="">
                      <input type="checkbox" name="" id="" />
                      &nbsp;
                      <a
                        data-bs-toggle="modal"
                        className="pop_terms_btn"
                        onClick={handleToggleRegisterModal}
                      >
                        I ACCEPT TERMS AND CONDITIONS
                      </a>
                    </label>
                  </div>
                </div>
                <div className="col-md-12">
                  <button
                    type="button"
                    className="btn btn-dark text-white float-start back mt-4 rounded-3 bg-color-back"
                    onClick={handleBack}
                  >
                    Back
                  </button>
                  <button
                    type="button"
                    className="btn text-white float-end next mt-4 rounded-3 bg-color-info"
                    onClick={handleNext}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {activeStep === 4 && (
          <div className="row row_top justify-content-center form-business">
            <div className="regsiter_second_step_bx">
              <div className="regsiter_overview_box">
                <div className="regsiter_step_1_view_left_sec_head">
                  <strong>{t("product_and_sponsor")}</strong>
                </div>
                <div className="row">
                  {/* <div className="col-md-4 mb-3 regsiter_overview_col">
                    <label htmlFor="">{t("product")}</label>
                    <strong>{formValues?.product?.name}</strong>
                  </div> */}
                  <div className="col-md-4 mb-3 regsiter_overview_col">
                    <label htmlFor="">{t("sponsor")}</label>
                    <strong>{data?.sponsorData?.username}</strong>
                  </div>
                  {/* <div className="col-md-4 mb-3 regsiter_overview_col">
                    <label htmlFor="">{t("pv")}</label>
                    <strong>{formValues?.pv}</strong>
                  </div> */}
                  {/* <div className="col-md-4 mb-3 regsiter_overview_col">
                    <label htmlFor="">{t("price")}</label>
                    <strong>{`$ ${formValues?.product?.price}`}</strong>
                  </div>
                  <div className="col-md-4 mb-3 regsiter_overview_col">
                    <label htmlFor="">{t("totalAmount")}</label>
                    <strong>{`$ ${formValues?.totalAmount}`}</strong>
                  </div> */}
                </div>

                <div className="regsiter_step_1_view_left_sec_head">
                  <strong>{t(`${data?.registrationSteps[1]?.label}`)}</strong>
                </div>

                <div className="row">
                  {Object.entries(formValues)?.map(
                    ([key, value]) =>
                      ![
                        "product",
                        "pv",
                        "regAmount",
                        "termsAndCondition",
                        "totalAmt",
                        "paymentType",
                        "customFields",
                        "bankReciept",
                        "totalAmount",
                        "username",
                        "password",
                        "country",
                        "state",
                      ].includes(key) && (
                        <div
                          className="col-md-4 mb-3 regsiter_overview_col"
                          key={key}
                        >
                          <label htmlFor="">{t(key)}</label>
                          <strong>{value}</strong>
                        </div>
                      )
                  )}
                  {formValues?.customField?.map(([key, value]) => (
                    <div className="col-md-4 mb-3 regsiter_overview_col">
                      <label htmlFor="">{t(key)}</label>
                      <strong>{value}</strong>
                    </div>
                  ))}
                </div>

                <div className="regsiter_step_1_view_left_sec_head">
                  <strong>{t(`${data?.registrationSteps[2]?.label}`)}</strong>
                </div>

                <div className="row">
                  <div className="col-md-4 mb-3 regsiter_overview_col">
                    <label htmlFor="">{t("username")}</label>
                    <strong>{formValues.username}</strong>
                  </div>
                  <div className="col-md-4 mb-3 regsiter_overview_col">
                    <label htmlFor="">{t("password")}</label>
                    <strong>********</strong>
                  </div>
                </div>

                <div className="col-md-12">
                  <button
                    type="button"
                    className="btn btn-dark text-white float-start back mt-4 rounded-3 bg-color-back"
                    onClick={handleBack}
                  >
                    Back
                  </button>
                  <button
                    type="button"
                    className="btn text-white float-end next mt-4 rounded-3 bg-color-info"
                    onClick={handleNext}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {activeStep === 5 && (
          <div className="row row_top justify-content-center form-business">
            <div className="col-lg-12 col-md-12" id="payment">
              <div className="payment_section_tab">
                <div className="regsiter_step_1_view_left_sec_head">
                  {data?.registrationSteps &&
                    t(`${data?.registrationSteps[activeStep - 1]?.label}`)}
                  <br />
                  {/* <strong>
                    {t("totalAmount")}:{" "}
                    {`$ ${formValues?.totalAmount ?? data?.regFee}`}
                  </strong> */}
                </div>

                <div className="row">
                  <div className="col-md-3 pe-0">
                    <div
                      className="nav nav-tabs payment_tab_reg"
                      id="nav-tab"
                      role="tablist"
                    >
                      {data?.paymentGateways?.map((tab) => (
                        <Fragment key={tab.id}>
                          <button
                            key={tab.id}
                            className={`nav-link ${
                              activeTab === tab.id ? "active" : ""
                            }`}
                            id={`nav-${tab.id}-tab`}
                            data-bs-toggle="tab"
                            data-bs-target={`#nav-${tab.id}`}
                            type="button"
                            role="tab"
                            aria-controls={`nav-${tab.id}`}
                            aria-selected={formValues.paymentType === tab.id}
                            {...register("paymentType", { required: true })}
                            onClick={() =>
                              handlePaymentTabClick(tab.id, tab.title)
                            }
                          >
                            <i className={tab?.icon}></i> {tab.title}
                          </button>
                        </Fragment>
                      ))}
                    </div>
                  </div>
                  <div className="col-md-9 ps-0">
                    <div
                      className="tab-content p-3 border mb-5 payment__tab_cnt"
                      id="nav-tabContent"
                    >
                      {formValues.paymentType === undefined ||
                        (formValues.paymentType === "" && (
                          <p>
                            <strong>{t("preferredPaymentPlaceholder")}</strong>
                          </p>
                        ))}
                      {data?.paymentGateways?.map((tab) => (
                        <div
                          key={tab.id}
                          className={`tab-pane fade ${
                            activeTab === tab.id ? "show active" : ""
                          }`}
                          id={`nav-${tab.id}`}
                          role="tabpanel"
                          aria-labelledby={`nav-${tab.id}-tab`}
                        >
                          {tab.title === "free-joining" && (
                            <p>
                              <strong>{t("freeJoinPlaceHolder")}</strong>
                            </p>
                          )}
                          {tab.title === "bank-transfer" && (
                            <div className="row">
                              <div className="col-md-12">
                                <div className="form-group mb-2">
                                  <label
                                    htmlFor="fileUpload"
                                    style={{ marginBottom: "10px" }}
                                  >
                                    {t("file_upload")}
                                  </label>
                                  <input
                                    type="file"
                                    placeholder=""
                                    className="form-control"
                                    style={{ height: "33px" }}
                                    name="fileUpload"
                                    onChange={handleFileChange}
                                  />
                                  {fileResponse?.success && (
                                    <div style={{ color: "green" }}>
                                      {t(fileResponse?.success)}
                                    </div>
                                  )}
                                  {fileResponse?.error && (
                                    <div style={{ color: "red" }}>
                                      {t(fileResponse?.error)}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-12">
                                <button
                                  href="#"
                                  className="btn btn-sm btn-primary mt-3"
                                  onClick={handleUpload}
                                  disabled={Upload?.isLoading}
                                >
                                  {Upload?.isLoading
                                    ? "Uploading..."
                                    : t("upload")}
                                </button>
                              </div>
                              {Upload?.data?.status === true && (
                                <>
                                  <img
                                    src={`${BASE_URL}${Upload?.data?.data?.file?.path}`}
                                    alt="receipt"
                                    style={{
                                      width: "160px",
                                      height: "160px",
                                      padding: "5px",
                                    }}
                                  />
                                  <div style={{ color: "green" }}>
                                    {Upload?.data?.message}
                                  </div>
                                </>
                              )}
                              {Upload?.data?.status === false && (
                                <span className="error-message-validator">
                                  {Upload?.data?.message}
                                </span>
                              )}
                            </div>
                          )}
                          {tab.title === "stripe" && (
                            <p>
                              <strong>Addon Coming Soon</strong>
                            </p>
                          )}
                          {tab.title === "paypal" && (
                            <p>
                              <strong>Addon Coming Soon</strong>
                            </p>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                {errors.paymentType && (
                  <span className="error-message-validator">*Required</span>
                )}
              </div>
              <button
                type="button"
                className="btn btn-dark text-white float-start back rounded-3 bg-color-back"
                onClick={handleBack}
              >
                {t("back")}
              </button>
              <button
                type="submit"
                className="btn text-white float-end submit-button rounded-3 bg-color-info"
                disabled={submitButtonActive}
                onClick={handleSubmit}
              >
                {t("finish")}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ReplicaRegisterForm;
