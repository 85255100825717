import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { ApiHook } from "../../hooks/apiHook";
import { toast } from "react-toastify";

export const ForgotPasswordForm = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [validationError, setValidationError] = useState("");
  const params = useParams();
  const navigate = useNavigate();

  const verifyHashKey = ApiHook.CallVerifyForgotPassword({
    hash: params.hashKey,
  });
  const passwordMutation = ApiHook.CallChangeForgotPassword();

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleSubmit = () => {
    // Password validation logic
    if (password.trim() === "") {
      setValidationError("Password cannot be empty");
      return;
    }
    if (password !== confirmPassword) {
      setValidationError("Passwords do not match");
      return;
    }


    passwordMutation.mutate(
      { password: password, hash: params.hashKey },
      {
        onSuccess: (res) => {
          if (res.status) {
            toast.success(res.data);
            setTimeout(() => {
              navigate("/login");
            }, 3000);
          } else {
            toast.error(res?.data?.description);
            setTimeout(() => {
              navigate("/login");
            }, 3000);
          }
        },
      }
    );

    // Reset validation error message
    setValidationError("");
  };

  if (verifyHashKey?.data?.status === false) {
    toast.error(verifyHashKey?.data?.data?.description);
    setTimeout(() => {
      navigate("/login");
    }, 3000);
  }

  return (
    verifyHashKey.data?.status && (
      <div>
        <section className="loginSection">
          <div className="container centerDiv">
            <div className="loginBgImg"></div>
            <div className="row justify-content-center">
              <div className="col-md-6 logincredDetail">
                <div className="loginBg">
                  <div className="loginFormSec p-5">
                    <div className="loginLogo">
                      <img src="/images/logo_user.png" alt="" />
                    </div>
                    <p>Enter your new Password</p>
                    <div className="loginFormSec mt-5">
                      <p className="text-start text-dark">
                        {`User Name: ${verifyHashKey.data?.data?.data?.username}`}
                      </p>
                      <div className="passwordInput">
                        <label htmlFor="password">
                          Enter your new password
                        </label>
                        <input
                          type="password"
                          id="password"
                          name="password"
                          placeholder="Enter your new password"
                          value={password}
                          onChange={handlePasswordChange}
                        />
                      </div>
                      <div className="passwordInput">
                        <label htmlFor="confirmPassword">
                          Confirm Password
                        </label>
                        <input
                          type="password"
                          id="confirmPassword"
                          name="confirmPassword"
                          placeholder="Confirm Password"
                          value={confirmPassword}
                          onChange={handleConfirmPasswordChange}
                        />
                      </div>
                      {validationError && (
                        <p className="text-danger">{validationError}</p>
                      )}
                    </div>
                    <div className="loginBtn">
                      <button
                        type="submit"
                        className="btn"
                        onClick={handleSubmit}
                      >
                        Change
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  );
};
