import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { profileDetailsSchema } from "../../Validator/profile";
import SubmitButton from "../Common/buttons/SubmitButton";
import { ApiHook } from "../../hooks/apiHook";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { updateProfile } from "../../store/reducers/userReducer";
import { validateAge } from "../../Validator/register";

const ProfileDetailsTab = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    register,
    formState: { errors },
    handleSubmit,
    trigger
  } = useForm({ resolver: yupResolver(profileDetailsSchema) });

  const [isEditModeEnabled, setIsEditModeEnabled] = useState(false);
  const profileData = useSelector(
    (state) => state?.user?.profile?.personalDetails
  );
  const updateMutation = ApiHook.CallUpdatePersonalDetails();
  const toggleEditMode = () => {
    setIsEditModeEnabled(!isEditModeEnabled);
  };

  const onSubmit = (data) => {
    updateMutation.mutateAsync(data, {
      onSuccess: (res) => {
        if (res.status) {
          dispatch(
            updateProfile({
              profileDetails: data,
            })
          );
          toast.success(res?.data);
        }
      },
    });
  };
  return (
    <div id="firstTab" className="tabcontent">
      {/* <div className="editSec">
        <div className="editBg">
          <span
            style={{ textDecoration: "none", cursor: "pointer" }}
            onClick={toggleEditMode}
          >
            <i
              className="fa-solid fa-pen-to-square"
              style={{ color: "#32009c" }}
            ></i>
          </span>
        </div>
      </div> */}
      <h3>{t("personalDetails")}</h3>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="tabcontent_form_section">
          <div className="mb-3 row tabBlockClass">
            <label
              htmlFor="1"
              className="col-sm-3 col-form-label labelWidthClass"
            >
              {t("firstName")}
            </label>
            <div className="col-md-9 col-sm-12 col-12">
              <input
                {...register("name")}
                defaultValue={profileData?.name}
                type="text"
                id="name"
                className="form-control"
                disabled={!isEditModeEnabled}
              />
              {errors.name && (
                <span className="validation-error-message">
                  {errors.name.message}
                </span>
              )}
            </div>
          </div>
          <div className="mb-3 row tabBlockClass">
            <label
              htmlFor="2"
              className="col-sm-3 col-form-label labelWidthClass"
            >
              {t("lastName")}
            </label>
            <div className="col-md-9 col-sm-12 col-12">
              <input
                {...register("secondName")}
                defaultValue={profileData?.secondName}
                type="text"
                id="secondName"
                className="form-control"
                disabled={!isEditModeEnabled}
              />
              {errors.secondName && errors.secondName.type === "required" && (
                <span className="validation-error-message">
                  {errors.secondName.message}
                </span>
              )}
            </div>
          </div>
          <div className="mb-3 row tabBlockClass">
            <label
              htmlFor="3"
              className="col-sm-3 col-form-label labelWidthClass"
            >
              {t("gender")}
            </label>
            <div className="col-md-9 col-sm-12 col-12">
              <select
                {...register("gender")}
                defaultValue={profileData?.gender}
                className="form-select"
                aria-label="Default select example"
                id="3"
                disabled={!isEditModeEnabled}
              >
                <option key="M" value={"M"}>
                  {t("male")}
                </option>
                <option key="F" value={"F"}>
                  {t("female")}
                </option>
                <option key="O" value={"O"}>
                  {t("other")}
                </option>
              </select>
            </div>
            <label
              htmlFor="3"
              className="col-sm-3 col-form-label labelWidthClass"
            >
              {t("date_of_birth")}
            </label>
            <div className="col-md-9 col-sm-12 col-12">
              {/* <select
                {...register("gender")}
                defaultValue={profileData?.gender}
                className="form-select"
                aria-label="Default select example"
                id="3"
                disabled={!isEditModeEnabled}
              >
                <option key="M" value={"M"}>
                  {t("male")}
                </option>
                <option key="F" value={"F"}>
                  {t("female")}
                </option>
                <option key="O" value={"O"}>
                  {t("other")}
                </option>
              </select> */}
              <input
                name={"date_of_birth"}
                type="date"
                disabled={!isEditModeEnabled}
                className={`form-control ${
                  errors["date_of_birth"] ? "error-field" : ""
                }`}
                placeholder={""}
                defaultValue={profileData?.dob}
                {...register(`date_of_birth`, {
                  required: {
                    value: true,
                    message: t("this_field_is_required"),
                  },
                  validate: (value) => validateAge(value, t),
                })}
                onBlur={async () => await trigger("date_of_birth")}
              />
              {/* {errors.date_of_birth && (
                <span className="error-message-validator">
                  {errors.date_of_birth.message}
                </span>
              )} */}
            </div>
          </div>
          {/* <div
            className={`paymenytLinkBtn ${isEditModeEnabled ? "disabled" : ""}`}
          >
            <SubmitButton
              isSubmitting={!isEditModeEnabled}
              className="btn"
              text="Update"
            />
          </div> */}
        </div>
      </form>
    </div>
  );
};

export default ProfileDetailsTab;
