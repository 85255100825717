import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export default function SkeletonComponent() {
  return (
    <>
      <div>
        <SkeletonTheme>
          <div style={{ width: "100%" }}>
            <Skeleton
              height={120}
              width={"25%"}
              borderRadius={"20px"}
              count={4}
              inline={true}
              duration={2.5}
            />
          </div>
          <Skeleton
            height={500}
            width={"100%"}
            count={1}
            inline={true}
            duration={2.5}
          />
        </SkeletonTheme>
      </div>
    </>
  );
}
