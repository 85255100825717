import API from "../../api/api";

const callGetApi = async (endpoint, params) => {
  try {
    const response = await API.get(endpoint, { params });
    if (response.status === 200) {
      return response.data;
    } else {
      return response;
    }
  } catch (error) {
    throw error;
  }
};

export const GenealogyService = {
  getTreelist: async (userId, userName) => {
    return callGetApi(
      `get-genealogy-tree?userId=${userId}&username=${userName}`
    );
  },
  getUnilevelMore: async (fatherId, position) => {
    const endpoint = `get-unilevel-more`;
    const params = { fatherId: fatherId, position: position };
    return callGetApi(endpoint, params);
  },
};

export const TreeViewService = {
  getTreelist: async (userId) => {
    return callGetApi(`get-tree-view?userId=${userId}`);
  },
  getTreelistSponsor: async (userId) => {
    return callGetApi(`get-tree-view-sponsor?userId=${userId}`);
  },
};

export const SponserTreeService = {
  getTreelist: async (userId, userName) => {
    return callGetApi(`get-sponsor-tree?userId=${userId}&username=${userName}`);
  },
};

export const DownlineMembersService = {
  callDownline: async (level, page, itemsPerPage, sortAsc) => {
    const endpoint = `get-downlines`;
    const params = {
      level: level,
      page: page,
      perPage: itemsPerPage,
      order: sortAsc ? "asc" : "desc",
    };
    return callGetApi(endpoint, params);
    // return callApi(`get-downlines?level=${level}&page=${page}&perPage=${itemsPerPage}`)
  },
  callOrders: async (start, end, page, itemsPerPage) => {
    const endpoint = `get-order-details`;
    const params = {
      start_date: start,
      end_date: end,
      page: page,
      perPage: itemsPerPage,
    };
    return callGetApi(endpoint, params);
  },
  callHeader: async () => {
    const endpoint = `get-downline-header`;
    return callGetApi(endpoint);
  },
  callHeaderOrder: async () => {
    const endpoint = `get-order-header`;
    return callGetApi(endpoint);
  },
};

export const ReferralMembersService = {
  callReferral: async (level, page, itemsPerPage, start, end, sort) => {
    const endpoint = `get-referrals`;
    const params = {
      level: level,
      page: page,
      perPage: itemsPerPage,
      start_date: start,
      end_date: end,
      order: sort ? "asc" : "desc",
    };
    return callGetApi(endpoint, params);
  },
  callHeader: async () => {
    const endpoint = `get-referral-header`;
    return callGetApi(endpoint);
  },
};
