import React, { useState } from "react";
import TreeViewNavbar from "../../components/Tree/TreeViewNavbar";
import TreeViewContent from "../../components/Tree/tree-view/TreeViewContent";
import { ApiHook } from "../../hooks/apiHook";

const TreeViewSponsor = () => {
  const [selectedUserId, setSelectedUserId] = useState("");
  const treeListData = ApiHook.CallTreeViewListSponsor(selectedUserId);
  if (treeListData.isFetching) {
    // return null;
  }

  return (
    <>
      <TreeViewNavbar menu={"cluster-tree"} />
      <TreeViewContent
        setSelectedUserId={setSelectedUserId}
      />
    </>
  );
};

export default TreeViewSponsor;
