const DEFAULT_KEY='af9a5872-aa1c-4cee-b9ed-84bf6b373bf6'

//demo
// const BASE_URL = "https://admin.vieroots.iossmlm.com/node-api";

// local
//  const BASE_URL = "http://192.168.21.19:5021/node-api";

//live
const BASE_URL = "https://backoffice.vieroots.com/node-api";



export { BASE_URL, DEFAULT_KEY }