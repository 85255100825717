import React from "react";

const ProductForm = () => {
  return (
    <div className="productFormSec">
      <div className="mb-3">
        <label htmlFor="quantity" className="form-label">
          Quantity
        </label>
        <input
          type="text"
          className="form-control"
          id="quantity"
          placeholder="2"
          disabled
        />
      </div>
      <div className="mb-3">
        <label htmlFor="total" className="form-label">
          Total
        </label>
        <input
          type="text"
          className="form-control"
          id="total"
          placeholder="100"
          disabled
        />
      </div>
      <div className="mb-3">
        <label htmlFor="totalPV" className="form-label">
          Total PV
        </label>
        <input
          type="text"
          className="form-control"
          id="totalPV"
          placeholder="300"
          disabled
        />
      </div>
      <div className="mb-3">
        <label htmlFor="description" className="form-label">
          Description
        </label>
        <input
          type="text"
          className="form-control"
          id="description"
          placeholder="Description"
          disabled
        />
      </div>
    </div>
  );
}

export default ProductForm