import React from "react";
import QuickBalance from "../../components/Dashboard/DashboardTiles";
import JoiningChart from "../../components/Dashboard/JoiningChart";
import TeamMembers from "../../components/Dashboard/TeamMembers";
import TeamMembersEarningSection from "../../components/Dashboard/TeamPerformance";
import EarningsExpenses from "../../components/Dashboard/Earnings";
import { ApiHook } from "../../hooks/apiHook";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import SkeletonComponent from "../../components/Dashboard/Skeleton";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const Dashboard = () => {
  const conversionFactor = useSelector(
    (state) => state?.user?.conversionFactor
  );
  const { t } = useTranslation();
  const joiningChartData = useSelector(
    (state) => state.dashboard?.dashboardOne
  );
  const user = JSON.parse(localStorage.getItem("user"));
  const dashboard = ApiHook.CallDashboardOne();
  const userSelectedCurrency = useSelector(
    (state) => state.user?.selectedCurrency
  );

  return (
    <>
      {dashboard.isFetching || dashboard.isLoading ? (
        <SkeletonComponent />
      ) : (
        <>
          <div className="page_head_top">{t("dashboard")}</div>
          <div className="center_content_head">
            <span>
              {t("welcome")} {user.fullName}{" "}
            </span>
          </div>
          <QuickBalance
            tiles={dashboard?.data?.tiles}
            currency={userSelectedCurrency}
            conversionFactor={conversionFactor}
          />
          <>
            {!dashboard?.data?.isCustomer && (
              <div className="joining_Teammbr_section">
                <div className="row">
                  <JoiningChart charts={joiningChartData} />
                  <TeamMembers members={dashboard?.data?.newMembers} />
                </div>
              </div>
            )}
          </>
          {!dashboard?.data?.isCustomer && (
            <div className="team_members_earning_section">
              <div className="row">
                <TeamMembersEarningSection
                  teamPerformance={dashboard?.data?.teamPerformance}
                  currency={userSelectedCurrency}
                  conversionFactor={conversionFactor}
                />
                <EarningsExpenses
                  earningAndExpense={dashboard?.data?.earningsAndExpenses}
                  currency={userSelectedCurrency}
                  conversionFactor={conversionFactor}
                />
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Dashboard;
