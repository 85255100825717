import React from "react";
import TableContent from "../Common/table/TableContent";
import TablePagination from "../Common/table/TablePagination";
import { ApiHook } from "../../hooks/apiHook";
import { useState } from "react";
import SubmitButton from "../Common/buttons/SubmitButton";
import { useTranslation } from "react-i18next";
import moment from "moment";

function ReferralMembersTable(props) {
  const { t } = useTranslation();
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const [level, setlevel] = useState("all");
  const [selectedLevel, setselectedLvel] = useState("all");

  const [startDate, setStartDate] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [selectedStartDate, setSelectedStartDate] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );

  const [endDate, setEndDate] = useState(
    moment().endOf("month").format("YYYY-MM-DD")
  );
  const [selectedEndDate, setSelectedEndDate] = useState(
    moment().endOf("month").format("YYYY-MM-DD")
  );

  const [sortAsc, setSortAsc] = useState(true);
  const sortHandler = () => {
    setSortAsc(!sortAsc);
  };
  console.log("sort ====== ",sortAsc);
  const referralMember = ApiHook.CallReferralMembers(
    level,
    props.currentPage,
    itemsPerPage,
    selectedStartDate,
    selectedEndDate,
    sortAsc
  );
  const referralHeader = ApiHook.CallReferralHead();
  const headers = [
    t("member_name"),
    t("placement"),
    t("sponsor"),
    t("total_pv"),
    t("level"),
  ];

  const levelChangehandler = (e) => {
    const { value } = e.target;
    setselectedLvel(value);
  };

  const SearchHandler = () => {
    setSelectedStartDate(startDate);
    setSelectedEndDate(endDate);
    setlevel(selectedLevel);
    props.setCurrentPage(1);
  };
  const dateHandler = (e) => {
    const { value, name } = e.target;
    if (name == "start_date") {
      setStartDate(value);
    }
    if (name == "end_date") {
      setEndDate(value);
    }
  };
  const resetHandler = () => {
    setlevel("all");
    setselectedLvel("all");
  };

  let startPage = 1;
  const toNextPage = () => {
    if (
      referralMember.data.data.currentPage < referralMember.data.data.totalPages
    ) {
      props.setCurrentPage(referralMember.data.data.currentPage + 1);
    }
  };

  const toLastPage = () => {
    props.setCurrentPage(referralMember?.data?.data?.totalPages);
  };
  const toPreviousPage = () => {
    if (referralMember?.data?.data.currentPage > startPage) {
      props.setCurrentPage(referralMember.data.data.currentPage - 1);
    }
  };

  const toFirstPage = () => {
    props.setCurrentPage(startPage);
  };
  const handleItemsPerPageChange = (event) => {
    const selectedValue = parseInt(event.target.value);
    setItemsPerPage(selectedValue);
    props.setCurrentPage(1);
  };

  return (
    <div className="ewallet_table_section">
      <div className="ewallet_table_section_cnt">
        <div className="filter_Section">
          <div className="row justify-content-between align-items-center">
            <div className="col-md-4">
              <div className="right_search_div d-flex gap-1">
                <div className="downMembBg">
                  <h5>{t("totalReferralMembers")}</h5>
                  <h4>{referralMember?.data?.data?.totalCount}</h4>
                </div>
                <div className="downMembBg">
                  <h5>{t("totalLevels")}</h5>
                  <h4>{referralHeader?.data?.data?.totalLevel}</h4>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="right_search_div d-flex gap-1">
                <span>start date:</span>
                <input
                  name="start_date"
                  type="date"
                  class="form-control"
                  onChange={dateHandler}
                  value={startDate}
                ></input>
                <span>end date:</span>
                <input
                  name="end_date"
                  type="date"
                  class="form-control"
                  onChange={dateHandler}
                  value={endDate}
                ></input>
                <select
                  name=""
                  className="form-control"
                  id=""
                  value={selectedLevel}
                  onChange={levelChangehandler}
                >
                  <option value="all">All</option>
                  {referralHeader?.data?.data?.totalLevel &&
                    Array.from(
                      { length: referralHeader?.data?.data?.totalLevel },
                      (_, index) => index + 1
                    ).map((items) => (
                      <option value={items} key={items}>
                        {items}
                      </option>
                    ))}
                </select>
                <SubmitButton
                  isSubmitting=""
                  text="Search"
                  className="btn btn-primary"
                  click={SearchHandler}
                  id="searchButton"
                />
                <SubmitButton
                  isSubmitting=""
                  text="Reset"
                  className="btn btn-secondary"
                  click={resetHandler}
                  id="resetButton"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="table-responsive min-hieght-table">
          <TableContent
            headers={headers}
            data={referralMember?.data?.data?.data}
            type="referral"
            sortHandler={sortHandler}
            sortAsc={sortAsc}
          />
          {referralMember?.data?.data?.data?.length !== 0 && (
            <TablePagination
              startPage={startPage}
              currentPage={referralMember?.data?.data?.currentPage}
              totalPages={referralMember?.data?.data?.totalPages}
              setCurrentPage={props.setCurrentPage}
              itemsPerPage={itemsPerPage}
              setItemsPerPage={setItemsPerPage}
              toNextPage={toNextPage}
              toLastPage={toLastPage}
              toPreviousPage={toPreviousPage}
              toFirstPage={toFirstPage}
              handleItemsPerPageChange={handleItemsPerPageChange}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default ReferralMembersTable;
