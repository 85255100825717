import { Form } from "react-bootstrap";
import SubmitButton from "../buttons/SubmitButton";
import { useState } from "react";
import { ApiHook } from "../../../hooks/apiHook";

const LeadsTableFilter = (props) => {
   const [searchKey, setSearchKey] = useState("");
  const searchResultMutation =  ApiHook.CallSearchLeads();

  const handleChange = (e) => {
    const { value } = e.target;
    setSearchKey(value);
    
  };
  const handleSearch =  () => {
     searchResultMutation.mutateAsync(searchKey,{
      onSuccess: (res) => {
        props?.setTableData(res?.data?.data);
      }
    });
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      // If Enter key is pressed, trigger the search
      handleSearch();
    }
  };

  return (
    <>
      <div className="filter_Section">
        <div className="row justify-content-between align-items-center">
          <div className="col-md-8">
            {/* <div className="right_search_div d-flex gap-1">
              <h4 style={{ fontSize: "15px" }}>
                Lead Capture Link:{" "}
                <a style={{ textDecoration: "none" }} href={props?.replicaUrl}>
                  {props?.replicaUrl}
                </a>
              </h4>
            </div> */}
          </div>
          <div className="col-md-4">
            <div className="right_search_div d-flex gap-1">
              <Form.Group>
                <Form.Control
                  id="Search"
                  type="text"
                  placeholder="Search"
                  onChange={(e) => handleChange(e)}
                  onKeyPress={(e) => handleKeyPress(e)}
                  value={searchKey}
                  required
                />
              </Form.Group>
              <SubmitButton
                className="btn btn-primary"
                text="Search"
                click={handleSearch}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeadsTableFilter;
