import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import CurrencyConverter from "../../Currency/CurrencyConverter";

const EarningsExpenses = ({
  earningAndExpense,
  currency,
  conversionFactor,
}) => {
  const [activeTab, setActiveTab] = useState(0);
  const { t } = useTranslation();

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  return (
    <div className="col-md-5">
      <div className="joinings_viewBox teamperfomance">
        <div className="joinings_viewBox_head">
          <h5>{t('earningsAndExpenses')}</h5>
        </div>
        <ul
          className="teamPerfomance_tab nav nav-tabs mb-3"
          id="ex1"
          role="tablist"
        >
          {earningAndExpense &&
            Object?.keys(earningAndExpense)?.map((sectionName, index) => (
              <li className="nav-item" role="presentation" key={index}>
                <Link
                  className={`nav-link${index === activeTab ? " active" : ""}`}
                  id={`ex1-tab-${index}`}
                  data-bs-toggle="tab"
                  role="tab"
                  aria-controls={sectionName}
                  aria-selected={index === activeTab}
                  onClick={() => handleTabClick(index)}
                >
                  {sectionName === "earnings" ? t("earnings") : t("expenses")}
                </Link>
              </li>
            ))}
        </ul>
        <div className="tab-content" id="ex2-content">
          {earningAndExpense &&
            Object?.keys(earningAndExpense)?.map((sectionName, index) => (
              <div
                className={`tab-pane${
                  index === activeTab ? " show active" : " fade"
                }`}
                id={sectionName}
                role="tabpanel"
                aria-labelledby={sectionName}
                key={index}
              >
                <div className="teammbrs_cnt">
                  {earningAndExpense[sectionName]?.length === 0 ? (
                    <div className="no-data-div">
                      <div className="no-data-div-image">
                        <img src="/images/nodata-image.png" alt="" />
                      </div>
                      <p>{t('noDataFound')}</p>
                    </div>
                  ) : (
                    earningAndExpense[sectionName].map((row, rowIndex) => (
                      <div className="earning_expence_row" key={rowIndex}>
                        <span>{t(row.amountType)}</span>
                        <strong
                          style={{
                            color:
                              sectionName === "earnings" ? "#03AD47" : "red",
                          }}
                        >{`${currency?.symbolLeft} ${CurrencyConverter(
                          row.amount,
                          conversionFactor
                        )}`}</strong>
                      </div>
                    ))
                  )}
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default EarningsExpenses;
