import React from "react";
import { useState } from "react";
import TreeViewNavbar from "../../components/Tree/TreeViewNavbar";
import OrdersTable from "../../components/Orders/OrdersTable";

const Orders = () => {
  const [currentPage, setCurrentPage] = useState(1);

  return (
    <>
      <OrdersTable
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </>
  );
};

export default Orders;
