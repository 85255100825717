import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import CurrencyConverter from "../../Currency/CurrencyConverter";

const TeamMembersEarningSection = ({
  teamPerformance,
  currency,
  conversionFactor,
}) => {
  const [activeTab, setActiveTab] = useState(0);
  const { t } = useTranslation();

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="col-md-7">
      <div className="joinings_viewBox teamperfomance">
        <div className="joinings_viewBox_head">
          <h5>{t('teamPerformance')}</h5>
        </div>
        <ul
          className="teamPerfomance_tab nav nav-tabs mb-3"
          id="ex1"
          role="tablist"
        >
          {teamPerformance &&
            Object?.keys(teamPerformance)?.map((sectionName, index) => (
              <li className="nav-item" role="presentation" key={index}>
                <Link
                  className={`nav-link ${activeTab === index ? "active" : ""}`}
                  id={`ex1-tab-${index}`}
                  data-bs-toggle="tab"
                  role="tab"
                  aria-controls={sectionName}
                  aria-selected={activeTab === index ? "true" : "false"}
                  onClick={() => handleTabChange(index)}
                >
                  {t(sectionName ? sectionName : "")}
                </Link>
              </li>
            ))}
        </ul>
        <div className="tab-content" id="ex1-content">
          {teamPerformance &&
            Object?.keys(teamPerformance)?.map((sectionName, index) => (
              <div
                className={`tab-pane fade ${
                  activeTab === index ? "show active" : ""
                }`}
                id={sectionName}
                role="tabpanel"
                aria-labelledby={sectionName}
                key={index}
              >
                <div className="top_earners_Section">
                  {teamPerformance[sectionName].length === 0 ? (
                    <div className="no-data-div">
                      <div className="no-data-div-image">
                        <img src="/images/nodata-image.png" alt="" />
                      </div>
                      <p>{t('noDataFound')}</p>
                    </div>
                  ) : (
                    teamPerformance[sectionName].map((item, index) => (
                      <div className="teammbrs_cnt_row" key={index}>
                        <div className="teammbrs_cnt_img">
                          {sectionName === "rankOverview" && (
                            <img src={"/images/rank.jpg"} alt="" />
                          )}
                          {sectionName === "packageOverview" && (
                            <img src={"/images/package.jpg"} alt="" />
                          )}
                          {(sectionName === "topRecruiters" ||
                            sectionName === "topEarners") && (
                            <img
                              src={item.image ? item.image : "/images/team2.png"}
                              alt=""
                            />
                          )}
                        </div>
                        <div className="teammbrs_cnt_name_dtl">
                          <div className="teammbrs_cnt_name">
                            {item.name}
                            <span>{item.username}</span>
                          </div>
                        </div>
                        {sectionName === "topEarners" && (
                          <div className="teamperfoamance_amount">
                            <strong style={{ color: "#03AD47" }}>{`${
                              currency?.symbolLeft
                            } ${CurrencyConverter(
                              item.balanceAmount,
                              conversionFactor
                            )}`}</strong>
                          </div>
                        )}
                        {(sectionName === "topRecruiters" ||
                          sectionName === "rankOverview" ||
                          sectionName === "packageOverview") && (
                          <div className="top_recuirters_num">
                            <span>{item.count}</span>
                          </div>
                        )}
                      </div>
                    ))
                  )}
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default TeamMembersEarningSection;
