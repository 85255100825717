import React, { useEffect, useRef, useState } from "react";
import { OverlayTrigger, Popover, Modal, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { formatDate } from "../../../utils/formateDate";
import { ApiHook } from "../../../hooks/apiHook";
import { useNavigate } from "react-router-dom";
import anime from "animejs/lib/anime.es.js";
import { useTranslation } from "react-i18next";

const TreeNode = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const data = props?.data;
  const plan = props?.plan;
  const NoProfile = "/images/user-profile.png";
  const [packModal, setPackModal] = useState(false);
  const [expanded, setExpanded] = useState(true);
  const [hoveredItemId, setHoveredItemId] = useState([]);
  const [isMoreId, setIsMoreId] = useState({
    fatherId: null,
    position: null,
  });
  const listRef = useRef(null);

  ApiHook.CallUnilevelMore(isMoreId);
  const regLink = ApiHook.CallregLink();

  const HandleExpand = (data) => {
    if (data?.children?.length === 0) {
      props.setSelectedUserId(data?.attributes?.id);
    } else {
      setExpanded(!expanded);
    }
  };

  const handleItemHover = (itemId) => {
    setHoveredItemId(itemId);
  };

  const handleDoubleClick = (data) => {
    props.setDoubleUser(data?.attributes?.id);
  };

  const handleIsMore = (data) => {
    setIsMoreId((prev) => ({
      ...prev,
      fatherId: data?.fatherId,
      position: data?.position,
    }));
  };

  const handleRegistration = (data) => {
    props.setParamsList({
      placement: data?.attributes?.parent,
      position: data?.attributes?.position,
      isRegFromTree: 1,
    });

    if (props.ecomStatus) {
      props.setLinkRegisterCheck(true);
    } else {
      navigate("/register", {
        state: {
          parent: data?.attributes?.parent,
          position: data?.attributes?.position,
        },
      });
    }
  };
  const toExternalRegistration = async (data) => {
    let link = await regLink.mutateAsync({
      placement: data?.attributes?.parent,
      position: data?.attributes?.position,
    });
    if (link) {
      setPackModal(false);
      if (link.status) {
        const url = link.data;
        window.open(url, "_blank");
      }
    }
  };

  const modalOpen = (data) => {
    setPackModal(true);
  };
  useEffect(() => {
    if (expanded) {
      anime({
        targets: listRef.current,
        translateY: [`${-20}px`, `${0}px`],
        opacity: [0, 1],
        duration: 500,
        easing: "easeInQuad",
      });
    }
  }, [expanded]);

  const popover = (
    <Popover id="popover">
      <div id="treeview_pop">
        <div className="card">
          <div className="card-img-top">
            <div className="card-img-top_img">
              <img
                src={
                  hoveredItemId?.tooltipData?.profilePic
                    ? hoveredItemId?.tooltipData?.profilePic
                    : NoProfile
                }
                alt="profile photo"
              />
            </div>
            <h5 className="card-title">
              {hoveredItemId?.tooltipData?.username}
            </h5>
            <p className="card-text">
              {hoveredItemId?.tooltipData?.fullName
                ? hoveredItemId?.tooltipData?.fullName
                : "username"}
            </p>
          </div>
          <div className="card-body">
            <div className="user_detail_tabl">
              <table>
                <tbody>
                  {hoveredItemId?.tooltipData?.tableData &&
                    Object?.entries(hoveredItemId.tooltipData.tableData)?.map(
                      ([key, value]) => (
                        <tr key={key}>
                          <td>{t(key)}</td>
                          <td>
                            {key === "joinDate" ||
                            (key === "activationDate" && value != "NA")
                              ? formatDate(value)
                              : value}
                          </td>
                        </tr>
                      )
                    )}
                </tbody>
              </table>
            </div>
            {hoveredItemId?.tooltipData?.rankDetails &&
              hoveredItemId?.tooltipData?.rankDetails?.name && (
                <a
                  className="btn btn-rank"
                  style={{
                    backgroundColor:
                      hoveredItemId?.tooltipData?.rankDetails?.color,
                  }}
                >
                  {hoveredItemId?.tooltipData?.rankDetails?.name}
                </a>
              )}
          </div>
        </div>
      </div>
    </Popover>
  );

  return (
    <>
      {!data ? (
        <>
          <div className="member-image">
            <img src={NoProfile} alt="Member" />
          </div>
        </>
      ) : (
        <li>
          {data?.isHidden ? (
            <>
              <a>
                <div className="member-view-box">
                  <div className="member-image">
                    {data?.isMore && plan === "Unilevel" ? (
                      <>
                        <div
                          className="right_more_user_expand_btn"
                          onClick={() => handleIsMore(data?.attributes)}
                        >
                          <i className="fas fa-angle-double-right"></i>
                        </div>
                        <div className="member-details-dwnl-bx">
                          {data?.attributes?.moreChildren} more
                        </div>
                      </>
                    ) : (
                      <span
                        className="pulse-button"
                        // onClick={() => handleRegistration(data)}
                        // onClick={() => modalOpen(data)}
                        style={{ backgroundColor: "#0000007a" }}
                      >
                        <div style={{ fontSize: "20px" }}>x</div>
                      </span>
                    )}
                  </div>
                </div>
              </a>
            </>
          ) : data?.isPlaceholder ? (
            <>
              <a>
                <div className="member-view-box">
                  <div className="member-image">
                    {data?.isMore && plan === "Unilevel" ? (
                      <>
                        <div
                          className="right_more_user_expand_btn"
                          onClick={() => handleIsMore(data?.attributes)}
                        >
                          <i className="fas fa-angle-double-right"></i>
                        </div>
                        <div className="member-details-dwnl-bx">
                          {data?.attributes?.moreChildren} more
                        </div>
                      </>
                    ) : (
                      <span
                        className="pulse-button"
                        // onClick={() => handleRegistration(data)}
                        onClick={() => modalOpen(data)}
                      >
                        +
                      </span>
                    )}
                  </div>
                </div>
              </a>
            </>
          ) : (
            <>
              <a
                onClick={() => HandleExpand(data)}
                onMouseEnter={() => handleItemHover(data)}
                onMouseLeave={() => handleItemHover([])}
                onDoubleClick={() => handleDoubleClick(data)}
              >
                <div className="member-view-box">
                  <OverlayTrigger
                    trigger={["hover", "focus"]}
                    placement="right"
                    overlay={popover}
                  >
                    <div className="member-image">
                      <img
                        src={
                          data?.attributes?.treeIcon === null ||
                          data?.attributes?.treeIcon === ""
                            ? NoProfile
                            : data?.attributes?.treeIcon
                        }
                        alt="Member"
                      />
                      <div className="member-details">
                        <h3>{data?.username}</h3>
                        <div className="member-details-downline-dtl">
                          {plan === "Binary" ? (
                            <>
                              <div className="member-details-dwnl-bx">
                                {t("left")}:{" "}
                                <strong>
                                  {data?.attributes?.leftChildrenCount}
                                </strong>
                              </div>
                              <div className="member-details-dwnl-bx">
                                {t("right")}:{" "}
                                <strong>
                                  {data?.attributes?.rightChildrenCount}
                                </strong>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="member-details-dwnl-bx">
                                Children:{" "}
                                <strong>
                                  {data?.attributes?.childrenCount}
                                </strong>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </OverlayTrigger>
                </div>
              </a>
              {data?.children?.length > 0 && expanded && (
                <ul ref={listRef}>
                  {data?.children?.map((child, index) => {
                    return (
                      <TreeNode
                        key={index}
                        data={child}
                        plan={plan}
                        ecomStatus={props.ecomStatus}
                        selectedUserId={props.selectedUserId}
                        setSelectedUserId={props.setSelectedUserId}
                        doubleUser={props.doubleUser}
                        setDoubleUser={props.setDoubleUser}
                        linkRegisterCheck={props.linkRegisterCheck}
                        setLinkRegisterCheck={props.setLinkRegisterCheck}
                        paramsList={props.paramsList}
                        setParamsList={props.setParamsList}
                      />
                    );
                  })}
                </ul>
              )}
            </>
          )}
        </li>
      )}
      {packModal && (
        <Modal show={packModal} onHide={() => setPackModal(false)}>
          <Modal.Header>
            <Modal.Title>Registration</Modal.Title>
          </Modal.Header>
          <Button
            onClick={() => handleRegistration(data)}
            style={{ padding: "20px" }}
            className="mb-2 m-3"
          >
            Free Registration
          </Button>
          <br />
          <Button
            variant="primary"
            onClick={() => toExternalRegistration(data)}
            style={{ padding: "20px" }}
            className=" m-3"
          >
            Product Purchase
          </Button>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setPackModal(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

const TreeNodeComponent = (props) => {
  const moduleStatus = useSelector(
    (state) => state.dashboard?.appLayout?.moduleStatus
  );
  const [linkRegisterCheck, setLinkRegisterCheck] = useState(false);
  const [paramsList, setParamsList] = useState({
    placement: "",
    position: "",
    isRegFromTree: "",
  });
  const genealogyList = useSelector((state) => state.tree?.genealogyList);
  const RegisterLink = ApiHook.CallRegisterLink(
    linkRegisterCheck,
    setLinkRegisterCheck,
    paramsList.placement,
    paramsList.position,
    paramsList.isRegFromTree
  );
  if (RegisterLink.isFetched) {
    window.location.href = RegisterLink.data?.link;
  }
  return (
    <>
      <TreeNode
        data={genealogyList}
        regLink={genealogyList.registrationUrl}
        plan={moduleStatus?.mlm_plan}
        ecomStatus={moduleStatus?.ecom_status}
        selectedUserId={props.selectedUserId}
        setSelectedUserId={props.setSelectedUserId}
        doubleUser={props.doubleUser}
        setDoubleUser={props.setDoubleUser}
        linkRegisterCheck={linkRegisterCheck}
        setLinkRegisterCheck={setLinkRegisterCheck}
        paramsList={paramsList}
        setParamsList={setParamsList}
      />
    </>
  );
};

export default TreeNodeComponent;
