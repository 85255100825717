import { React, useState } from "react";
import UserProfileAvatar from "../../components/Profile/UserProfileAvatar";
import UserProfileExtra from "../../components/Profile/UserProfileExtra";
import ChangePasswordModal from "../../components/Common/modals/ChangePasswordModal";
import ChangeTransPassModal from "../../components/Common/modals/ChangeTransPassModal";
import UserProfileTabs from "../../components/Profile/UserProfileTabs";
import { ApiHook } from "../../hooks/apiHook";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";

const ProfileLayout = () => {
    const { t } = useTranslation();
    const [showPasswordModal, setShowPasswordModal] = useState(false);
    const [showTransPasswordModal, setShowTransPasswordModal] = useState(false);

    const handleClosePasswordModal = () => {
        setShowPasswordModal(!showPasswordModal);
    };
    const handleCloseTransPasswordModal = () => {
        setShowTransPasswordModal(!showTransPasswordModal);
    };
    const Profile = ApiHook.CallProfile();
    const profile = useSelector((state) => state.user?.profile);
    const moduleStatus = useSelector((state) => state.dashboard?.appLayout?.moduleStatus);
    const userDashboardResponse = useQuery({
        queryKey: ["dashboard-user-profile"],
    });
    if(Profile.isFetching) {
        // return null
    }
    return (
        <>
            <div className="page_head_top">{t('profileView')}</div>
            <div className="profileBgBox">
                <div className="row align-items-center">
                    <UserProfileAvatar profile={profile?.profile} moduleStatus={moduleStatus} userKyc={userDashboardResponse?.data?.userProfile?.kycStatus}/>
                    <UserProfileExtra profile={profile?.profile}
                        handleClosePasswordModal={handleClosePasswordModal}
                        handleCloseTransPasswordModal={handleCloseTransPasswordModal}
                        moduleStatus={moduleStatus}
                    />
                </div>
            </div>
            <UserProfileTabs profile={profile} />
            <ChangePasswordModal showModal={showPasswordModal} onHide={handleClosePasswordModal} passwordPolicy={profile?.passwordPolicy}/>
            <ChangeTransPassModal showModal={showTransPasswordModal} onHide={handleCloseTransPasswordModal} passwordPolicy={profile?.passwordPolicy}/>
        </>
    )
}

export default ProfileLayout