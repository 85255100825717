import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import SubmitButton from "../Common/buttons/SubmitButton";
import { ApiHook } from "../../hooks/apiHook";
import { toast } from "react-toastify";
const BankDetailsTab = ({ bank }) => {
  const { t } = useTranslation();
  const [bankDetails, setBankDetails] = useState({
    bankName: bank?.bankName ?? "",
    branchName: bank?.branchName ?? "",
    holderName: bank?.holderName ?? "",
    accountNo: bank?.accountNo ?? "",
    ifsc: bank?.ifsc ?? "",
    pan: bank?.pan ?? "",
  });
  const isSubmittingRef = useRef(false);
  const [isEditModeEnabled, setIsEditModeEnabled] = useState(false);

  const updateBankMutation = ApiHook.CallUpdateBankDetails(bankDetails);

  const toggleEditMode = () => {
    setIsEditModeEnabled(!isEditModeEnabled);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "accountNo") {
      const integerRegex = /^[0-9]+$/;
      if (!integerRegex.test(value)) {
        return;
      }
    }
    setBankDetails((prevCredentials) => ({
      ...prevCredentials,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!isSubmittingRef.current) {
      isSubmittingRef.current = true;
      updateBankMutation.mutate(bankDetails, {
        onSuccess: (res) => {
          toast.success(res?.data);
          setIsEditModeEnabled(false);
        },
      });
      isSubmittingRef.current = false;
    }
  };

  return (
    <div id="thirdTab" className="tabcontent">
      <div className={`editSec ${isEditModeEnabled ? "disabled" : ""}`}>
        <div className="editBg">
          <span
            style={{ textDecoration: "none", cursor: "pointer" }}
            onClick={toggleEditMode}
          >
            <i
              className="fa-solid fa-pen-to-square"
              style={{ color: "#32009c" }}
            ></i>
          </span>
        </div>
      </div>
      <h3>{t("bankDetails")}</h3>
      <form onSubmit={handleSubmit}>
        <div className="tabcontent_form_section">
          <div className="mb-3 row tabBlockClass">
            <label
              htmlFor="bankName"
              className="col-sm-3 col-form-label labelWidthClass"
            >
              {t("bankName")}
            </label>
            <div className="col-md-9 col-sm-12 col-12">
              <input
                name="bankName"
                type="text"
                className="form-control"
                id="bankName"
                value={bankDetails?.bankName}
                disabled={!isEditModeEnabled}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="mb-3 row tabBlockClass">
            <label
              htmlFor="branchName"
              className="col-sm-3 col-form-label labelWidthClass"
            >
              {t("branchName")}
            </label>
            <div className="col-md-9 col-sm-12 col-12">
              <input
                name="branchName"
                type="text"
                className="form-control"
                id="branchName"
                value={bankDetails?.branchName}
                disabled={!isEditModeEnabled}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="mb-3 row tabBlockClass">
            <label
              htmlFor="accountHolder"
              className="col-sm-3 col-form-label labelWidthClass"
            >
              {t("accountHolder")}
            </label>
            <div className="col-md-9 col-sm-12 col-12">
              <input
                name="holderName"
                type="text"
                className="form-control"
                id="holderName"
                value={bankDetails?.holderName}
                onChange={handleChange}
                disabled={!isEditModeEnabled}
              />
            </div>
          </div>
          <div className="mb-3 row tabBlockClass">
            <label
              htmlFor="accountNumber"
              className="col-sm-3 col-form-label labelWidthClass"
            >
              {t("accountNumber")}
            </label>
            <div className="col-md-9 col-sm-12 col-12">
              <input
                name="accountNo"
                type="text"
                className="form-control"
                id="accountNumber"
                value={bankDetails?.accountNo}
                onChange={handleChange}
                disabled={!isEditModeEnabled}
              />
            </div>
          </div>
          <div className="mb-3 row tabBlockClass">
            <label
              htmlFor="ifscCode"
              className="col-sm-3 col-form-label labelWidthClass"
            >
              {t("ifscCode")}
            </label>
            <div className="col-md-9 col-sm-12 col-12">
              <input
                name="ifsc"
                type="text"
                className="form-control"
                id="ifscCode"
                value={bankDetails?.ifsc}
                onChange={handleChange}
                disabled={!isEditModeEnabled}
              />
            </div>
          </div>
          <div className="mb-3 row tabBlockClass">
            <label
              htmlFor="pan"
              className="col-sm-3 col-form-label labelWidthClass"
            >
              {t("panNumber")}
            </label>
            <div className="col-md-9 col-sm-12 col-12">
              <input
                name="pan"
                type="text"
                className="form-control"
                id="pan"
                value={bankDetails?.pan}
                onChange={handleChange}
                disabled={!isEditModeEnabled}
              />
            </div>
          </div>
          <div
            className={`paymenytLinkBtn ${isEditModeEnabled ? "disabled" : ""}`}
          >
            <SubmitButton
              className="btn"
              isSubmitting={!isEditModeEnabled}
              text="update"
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default BankDetailsTab;
