import React from "react";
import Skeleton,{SkeletonTheme} from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export default function SkeletonComponent() {
  return (
    <>
    <div>

     <SkeletonTheme >
      <Skeleton count={1} inline ={true} width={"25%"}/>
      <div style={{ width: "100%" }}>
        <Skeleton
          height={100}
          width={"25%"}
          borderRadius={"20px"}
          count={4}
          inline={true}
          duration={2.5}
        />
      </div>
      <Skeleton height={230} width={"60%"} count={1} inline={true} duration={2.5} />
      <Skeleton height={230} width={"40%"} count={1} inline={true} duration={2.5} />
      <Skeleton height={250} width={"60%"} count={1} inline={true} duration={2.5} />
      <Skeleton height={250} width={"40%"} count={1} inline={true} duration={2.5} />
     </SkeletonTheme >
     {/* <Skeleton variant="rectangular" width={210} height={118} />
     <Skeleton variant="rectangular" width={210} height={118} />
     <Skeleton variant="rectangular" width={210} height={118} />
     <Skeleton variant="rectangular" width={210} height={118} />
     <Skeleton variant="rectangular" width={210} height={118} /> */}
     </div>

    </>
  );
}
